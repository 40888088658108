.mainVisual {
  position: relative;

  &__bg {
    @include media-breakpoint-up(md) {
      display: flex;
    }

    .bg {
      position: relative;

      @include media-breakpoint-up(md) {
        width: 50%;
      }

      &::before {
        position: absolute;
        content: '';
        background: no-repeat center top / contain;
        mix-blend-mode: overlay;
      }

      &--1 {
        &::before {
          top: 60px;
          left: 4px;
          width: 58px;
          height: 224px;
          background-image: url('/assets/images/pages/index/mv_bg-typography1.svg');

          @include media-breakpoint-up(md) {
            top: 113px;
            left: 10px;
            width: 133px;
            height: 546px;
            background-image: url('/assets/images/pages/index/mv_bg-typography1_md.svg');
          }
        }
      }

      &--2 {
        &::before {
          right: 7px;
          bottom: 11px;
          width: 31px;
          height: 215px;
          background-image: url('/assets/images/pages/index/mv_bg-typography2.svg');

          @include media-breakpoint-up(md) {
            top: 119px;
            right: 15px;
            width: 72px;
            height: 525px;
            background-image: url('/assets/images/pages/index/mv_bg-typography2_md.svg');
          }
        }
      }

      img {
        width: 100%;
        max-height: 100vh;
        object-fit: cover;
      }
    }
  }

  &__contents {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include media-breakpoint-up(md) {
      min-width: map-get($grid-breakpoints, xl);
    }

    .hdg {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;

      &__inner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 359px;
        height: 93px;

        @include media-breakpoint-up(md) {
          width: 177px;
          height: 625px;
        }
      }

      &__frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        &::before,
        &::after {
          position: absolute;
          width: 34px;
          height: 34px;
          content: '';
          background: #fff no-repeat center/contain;
          mask-image: url('/assets/images/pages/common/compornent_corner-frame.svg');

          @include media-breakpoint-up(md) {
            width: 58px;
            height: 58px;
          }
        }

        &--top {
          &::before,
          &::after {
            top: 0;
          }

          &::before {
            left: 0;
          }

          &::after {
            right: 0;
            transform: rotate(90deg);
          }
        }

        &--bottom {
          &::before,
          &::after {
            bottom: 0;
          }

          &::before {
            left: 0;
            transform: rotate(270deg);
          }

          &::after {
            right: 0;
            transform: rotate(180deg);
          }
        }
      }

      &__txt {
        font-family: $font-serif;
        font-size: 36px;
        color: $color-baika-pink;
        text-shadow: 0 0 34px #fff;
        letter-spacing: -0.1em;

        @include media-breakpoint-up(md) {
          font-size: 56px;
          letter-spacing: 0;
          writing-mode: vertical-rl;
        }
      }
    }

    .news {
      position: absolute;
      right: 45px;
      bottom: 25px;
      left: 0;
      display: block;
      padding: 10px 15px;
      background: rgba(#fff, 0.9);
      border-radius: 0 8px 8px 0;

      @include media-breakpoint-up(md) {
        right: auto;
        width: 432px;
        padding: 15px 15px 15px 25px;
      }

      &__head {
        display: flex;
        align-items: center;
      }

      &__date {
        font-size: 14px;
        font-weight: 400;
        color: #828282;
        letter-spacing: 0;
      }

      &__label {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px 6px;
        margin: 0 0 0 5px;
        font-size: 12px;
        font-weight: 700;
        color: $color-baika-pink;
        background: rgba($color-baika-pink, 0.14);
        border-radius: 12px;

        @include media-breakpoint-up(md) {
          padding: 2px 12px;
          margin: 0 0 0 15px;
          font-size: 13px;
        }
      }

      &__txt {
        margin: 5px 0 0;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.75;
        letter-spacing: 0;

        @include media-breakpoint-up(md) {
          font-size: 16px;
        }
      }
    }
  }
}
