.opening {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: #000;

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;

    .mask {
      width: 50%;
      height: 100%;
      background: #0f0;

      + .mask {
        background: #f00;
      }
    }
  }
}
