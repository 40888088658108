.secSchool {
  &__head {
    background: var(--headBg);

    @include media-breakpoint-up(md) {
      display: flex;
    }

    .headVisual {
      width: 50%;

      @include media-breakpoint-up(md) {
        position: relative;
      }

      &::before {
        @include media-breakpoint-up(md) {
          position: absolute;
          content: '';
          background: no-repeat center/contain;
          mix-blend-mode: overlay;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .headContents {
      position: relative;
      z-index: 0;
      padding: 65px 35px 95px;
      overflow: hidden;
      font-family: $font-serif;
      color: #fff;

      @include media-breakpoint-up(md) {
        width: 50%;
        padding: calc(110 / 1440 * 100%) calc(100 / 1440 * 100%) 0;
      }

      &::before {
        position: absolute;
        right: -15px;
        bottom: 20px;
        z-index: -1;
        width: 278px;
        height: 219px;
        content: '';

        @include media-breakpoint-up(md) {
          right: 1px;
          bottom: 10px;
          width: 520px;
          height: 410px;
        }
      }

      &__frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        &::before,
        &::after {
          position: absolute;
          width: 44px;
          height: 44px;
          content: '';
          background: #fff no-repeat center/contain;
          mask-image: url('/assets/images/pages/common/compornent_corner-frame.svg');
        }

        &--top {
          &::before,
          &::after {
            top: 20px;
          }

          &::before {
            left: 20px;
          }

          &::after {
            right: 20px;
            transform: rotate(90deg);
          }
        }

        &--bottom {
          &::before,
          &::after {
            bottom: 20px;
          }

          &::before {
            left: 20px;
            transform: rotate(270deg);
          }

          &::after {
            right: 20px;
            transform: rotate(180deg);
          }
        }
      }

      .contentsHdg {
        position: relative;
        font-size: 46px;
        font-weight: 400;

        @include media-breakpoint-up(md) {
          font-size: 74px;
        }
      }

      .contentsImg {
        margin: 25px 0 0;

        img {
          width: 100%;
          height: auto;
        }
      }

      .contentsLead {
        position: relative;
        margin: 25px 0 0;
        font-size: 28px;
        line-height: 1.6;
        letter-spacing: 0.25em;

        @include media-breakpoint-up(md) {
          margin: (60 / 1440 * 100%) 0 0;
          font-size: 36px;
        }

        &::before {
          position: absolute;
          width: 53px;
          height: 31px;
          content: '';
          background: url('/assets/images/pages/index/school_visual-deco2.svg')
            no-repeat center/contain;

          @include media-breakpoint-up(md) {
            width: 44px;
            height: 61px;
            background: url('/assets/images/pages/index/school_visual-deco1.svg')
              no-repeat center/contain;
          }
        }
      }

      .contentsTxt {
        margin: 20px 0 0;
        font-size: 14px;
        line-height: 2.235;
        letter-spacing: 0;

        @include media-breakpoint-up(md) {
          margin: (20 / 1440 * 100%) 0 0;
          font-size: 17px;
        }

        &__inner {
          position: relative;
          display: inline-block;

          &::before {
            @include media-breakpoint-up(md) {
              position: absolute;
              width: 72px;
              height: 43px;
              content: '';
              background: url('/assets/images/pages/index/school_visual-deco2.svg')
                no-repeat center/contain;
            }
          }
        }
      }

      .contentsLinkList {
        display: flex;
        flex-wrap: wrap;
        margin: 15px -10px 0;
        color: #fff;

        @include media-breakpoint-up(md) {
          margin: (45 / 1440 * 100%) -17px 0;
        }

        &__item {
          width: 50%;
          padding: 0 10px;

          @include media-breakpoint-up(md) {
            padding: 0 17px;
          }

          .link {
            display: flex;
            align-items: center;
            height: 58px;
            font-family: $font-serif;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;

            @include media-breakpoint-up(md) {
              height: 65px;
              font-size: 20px;
              border-bottom: 1px solid rgba(#d9d9d9, 0.3);
            }

            &__txt {
              @include a-hover-fade();

              &::after {
                display: inline-block;
                width: 6.5px;
                height: 11px;
                margin: 0 0 0 12px;
                content: '';
                background: #fff no-repeat center / contain;
                mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 6.5 11"><path d="M1 11c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.8-3.8L.3 1.7C-.1 1.3-.1.7.3.3s1-.4 1.4 0l4.5 4.5c.4.4.4 1 0 1.4l-4.5 4.5c-.2.2-.4.3-.7.3z"/></svg>');
              }
            }
          }

          &--full {
            width: 100%;
          }

          &:nth-child(n + 3) {
            .link {
              border-top: 1px solid rgba(#d9d9d9, 0.3);

              @include media-breakpoint-up(md) {
                border-top: none;
              }
            }
          }

          &:last-child {
            .link {
              @include media-breakpoint-up(md) {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }

  &__course {
    overflow: hidden;

    .course {
      position: relative;
      z-index: 0;
      overflow: hidden;
      background: var(--bgColor);

      @include media-breakpoint-up(md) {
        display: flex;
        width: 50%;
      }

      &::before,
      &::after {
        position: absolute;
        z-index: -1;
        content: '';
      }

      &::before {
        top: -22px;
        left: 14px;
        width: 89px;
        height: 104px;
        background: url('/assets/images/pages/index/school_course_bg-deco1.svg')
          no-repeat center/contain;
      }

      &::after {
        top: 10px;
        right: 5px;
        width: 88px;
        height: 112px;
        background: url('/assets/images/pages/index/school_course_bg-deco2.svg')
          no-repeat center/contain;
      }

      &__container {
        position: relative;
        padding: 35px 25px 40px 50px;

        &::before {
          position: absolute;
          bottom: 60px;
          left: 30px;
          z-index: -1;
          width: 115px;
          height: 145px;
          content: '';
          background: url('/assets/images/pages/index/school_course_bg-deco3.svg')
            no-repeat center/contain;
        }
      }

      &__visual {
        position: relative;
        display: inline-block;

        @include hover() {
          .visualImg {
            &::before {
              opacity: 0.4;
            }
          }

          .visualHdg {
            color: #fff;

            h3,
            p {
              &::before {
                background: var(--hdgColor);
              }
            }
          }

          .visualIcon {
            &::after {
              opacity: 1;
              transform: scale(1.15);
            }
          }
        }

        .visualImg {
          position: relative;

          &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            /* stylelint-disable */
            background: linear-gradient(
              0,
              var(--iconColor),
              var(--iconColor) 20%,
              transparent 75%
            );
            /* stylelint-enable */
            border-radius: 10px;
            opacity: 0;
            transition: opacity 0.4s $ease-out-sine;
          }

          img {
            width: 100%;
            height: auto;
          }
        }

        .visualHdg {
          position: absolute;
          top: 10px;
          left: -25px;
          z-index: 1;
          display: flex;
          align-items: flex-start;
          font-family: $font-serif;
          color: var(--hdgColor);
          transition: color 0.4s $ease-out-sine;

          @include media-breakpoint-up(md) {
            top: 15px;
            left: -30px;
          }

          &__main,
          &__sub {
            margin: 0 0.2rem 0 0;
            letter-spacing: 0.075em;
            writing-mode: vertical-rl;

            > * {
              font-weight: 400;
              line-height: 1;

              &::before {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                content: '';
                background: #fff;
              }
            }

            .num {
              text-combine-upright: all;
            }
          }

          &__main {
            > h3 {
              position: relative;
              z-index: 0;
              padding: 5px;
              font-size: 28px;

              @include media-breakpoint-up(md) {
                padding: 8px 5px;
                font-size: 40px;
              }
            }
          }

          &__sub {
            > p {
              position: relative;
              z-index: 0;
              padding: 5px;
              font-size: 14px;

              @include media-breakpoint-up(md) {
                padding: 8px 5px;
                font-size: 20px;
              }
            }
          }
        }

        .visualIcon {
          position: absolute;
          right: 20px;
          bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 70px;
          height: 70px;
          background: var(--iconColor);
          border-radius: 50%;
          box-shadow: 0 0 12px rgba(#000, 0.25);

          @include media-breakpoint-up(md) {
            right: 10px;
            bottom: 10px;
          }

          &::before {
            width: 8px;
            height: 14px;
            content: '';
            background: #fff no-repeat center / contain;
            mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 6.5 11"><path d="M1 11c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.8-3.8L.3 1.7C-.1 1.3-.1.7.3.3s1-.4 1.4 0l4.5 4.5c.4.4.4 1 0 1.4l-4.5 4.5c-.2.2-.4.3-.7.3z"/></svg>');
          }

          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            border: 2px solid var(--iconColor);
            border-radius: 50%;
            transition: transform 0.4s $ease-out-sine;
          }
        }
      }

      &__txt {
        margin: 20px 0 0;
        font-family: $font-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 2.125;
        letter-spacing: 0;

        @include media-breakpoint-up(md) {
          margin: 25px 0 0;
          font-size: 16px;
        }
      }

      &--standard {
        --hdgColor: #fac529;
        --iconColor: #f9bf11;
        --bgColor: #f8edcd;
      }

      &--art {
        --hdgColor: #a0ce7e;
        --iconColor: #95c970;
        --bgColor: #ddedd1;
      }

      &--advanced {
        --hdgColor: #6879ba;
        --iconColor: #6879ba;
        --bgColor: #eaedfa;
      }

      &--nurse {
        --hdgColor: #5bc3c4;
        --iconColor: #49bcbd;
        --bgColor: #d4edee;
      }

      &--global {
        --hdgColor: #e984a1;
        --iconColor: #e67696;
        --bgColor: #faecf0;
      }

      &--performing {
        --hdgColor: #c186b9;
        --iconColor: #ba79b1;
        --bgColor: #f3e4f1;
      }

      &--career {
        --hdgColor: #6abd79;
        --iconColor: #6abd79;
        --bgColor: #dff0e2;
      }
    }
  }

  &__bnr {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 35px 15px;
    background: var(--bnrBg);

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 40px;
      justify-content: center;
      padding: 50px 0;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: '';
      background: url('/assets/images/pages/index/repeat-bg_school_bnr.png')
        repeat center/59px auto;
      opacity: 0.2;
    }

    .bnr {
      @include media-breakpoint-up(md) {
        width: 588px;
      }

      @include a-hover-fade();

      &__container {
        position: relative;
        display: block;
        padding: 8px;
        border-image-slice: 30;
        border-image-width: 25px;
        border-image-outset: 0;
        border-image-repeat: round;

        @include a-hover-fade();
      }

      &__inner {
        position: relative;
        z-index: 0;
        display: flex;
        align-items: center;

        &::before {
          position: absolute;
          top: 7px;
          right: 7px;
          bottom: 7px;
          left: 7px;
          z-index: -1;
          content: '';
        }
      }

      &__img {
        width: 112px;

        @include media-breakpoint-up(md) {
          width: auto;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      &__txt {
        margin: 0 0 0 15px;
        font-family: $font-serif;
        font-size: 16px;
        line-height: 1.3;
        font-weight: 500;
        letter-spacing: 0;
        color: $color-baika-pink;

        @include media-breakpoint-up(md) {
          margin: 0 0 0 30px;
          font-size: 22px;
        }
      }

      &__arw {
        position: absolute;
        top: 50%;
        right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        transform: translateY(-50%);

        &::before {
          display: block;
          width: 6.5px;
          height: 11px;
          content: '';
          background: $color-baika-pink no-repeat center / contain;
          mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 6.5 11"><path d="M1 11c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.8-3.8L.3 1.7C-.1 1.3-.1.7.3.3s1-.4 1.4 0l4.5 4.5c.4.4.4 1 0 1.4l-4.5 4.5c-.2.2-.4.3-.7.3z"/></svg>');
        }
      }
    }
  }

  &--j {
    .secSchool__head {
      --headBg: #f18d02;

      .headVisual {
        &::before {
          @include media-breakpoint-up(md) {
            top: 23px;
            left: 10px;
            width: 192px;
            height: 762px;
            background-image: url('/assets/images/pages/index/school_visual-typography-j_md.svg');
          }
        }
      }

      .headContents {
        &::before {
          background: url('/assets/images/pages/index/school_visual-j-object.svg')
            no-repeat center/contain;
        }

        .contentsHdg {
          &::before {
            position: absolute;
            top: 25px;
            right: -10px;
            width: 30px;
            height: 42px;
            content: '';
            background: url('/assets/images/pages/index/school_visual-deco1.svg')
              no-repeat center/contain;

            @include media-breakpoint-up(md) {
              display: none;
            }
          }
        }

        .contentsLead {
          &::before {
            top: 0;
            right: -10px;

            @include media-breakpoint-up(md) {
              top: -59px;
              right: auto;
              left: -61px;
            }
          }
        }

        .contentsTxt {
          &__inner {
            @include media-breakpoint-up(md) {
              &::before {
                @include media-breakpoint-up(md) {
                  right: 2px;
                  bottom: -63px;
                }
              }
            }
          }
        }
      }
    }

    .secSchool__course {
      .courseWrap {
        @include media-breakpoint-up(md) {
          display: flex;
        }
      }

      .course {
        &:nth-child(odd) {
          &::before {
            @include media-breakpoint-up(md) {
              top: 10px;
              left: calc(50% - 410px);
              width: 180px;
              height: 210px;
              background: url('/assets/images/pages/index/school_course_bg-deco1-1_md.svg')
                no-repeat center/contain;
            }
          }

          &::after {
            @include media-breakpoint-up(md) {
              top: 97px;
              left: calc(50% + 226px);
              width: 164px;
              height: 209px;
              background: url('/assets/images/pages/index/school_course_bg-deco1-2_md.svg')
                no-repeat center/contain;
            }
          }

          .course__container {
            @include media-breakpoint-up(md) {
              padding: 60px 100px 100px 125px;
            }

            &::before {
              @include media-breakpoint-up(md) {
                bottom: 75px;
                left: calc(50% - 375px);
                width: 180px;
                height: 228px;
                background: url('/assets/images/pages/index/school_course_bg-deco1-3_md.svg')
                  no-repeat center/contain;
              }
            }
          }
        }

        &:nth-child(even) {
          &::before {
            @include media-breakpoint-up(md) {
              top: -18px;
              left: calc(50% + 176px);
              width: 161px;
              height: 207px;
              background: url('/assets/images/pages/index/school_course_bg-deco2-1_md.svg')
                no-repeat center/contain;
            }
          }

          &::after {
            @include media-breakpoint-up(md) {
              top: auto;
              bottom: 216px;
              left: calc(50% - 425px);
              width: 273px;
              height: 247px;
              background: url('/assets/images/pages/index/school_course_bg-deco2-2_md.svg')
                no-repeat center/contain;
            }
          }

          .course__container {
            @include media-breakpoint-up(md) {
              padding: 60px 105px 100px 115px;
            }

            &::before {
              @include media-breakpoint-up(md) {
                bottom: -53px;
                left: calc(50% + 158px);
                width: 282px;
                height: 275px;
                background: url('/assets/images/pages/index/school_course_bg-deco2-3_md.svg')
                  no-repeat center/contain;
              }
            }
          }
        }
      }
    }

    .secSchool__bnr {
      --bnrBg: linear-gradient(90deg, #ffb11b, #f18d02);

      .bnr {
        &__container {
          border-image-source: url('/assets/images/pages/common/compornent_border-frame-yellow.png');
        }

        &__inner {
          &::before {
            background: #ffef9d;
          }
        }
      }
    }
  }

  &--h {
    .secSchool__head {
      --headBg: #b72f8c;

      .headVisual {
        &::before {
          @include media-breakpoint-up(md) {
            top: 44px;
            right: 28px;
            width: 103px;
            height: 733px;
            background-image: url('/assets/images/pages/index/school_visual-typography-h_md.svg');
          }
        }
      }

      .headContents {
        &::before {
          background: url('/assets/images/pages/index/school_visual-h-object.svg')
            no-repeat center/contain;
        }

        .contentsLead {
          &::before {
            right: 10px;
            bottom: 10px;

            @include media-breakpoint-up(md) {
              top: 5px;
              right: 10px;
            }
          }
        }

        .contentsTxt {
          &__inner {
            @include media-breakpoint-up(md) {
              &::before {
                @include media-breakpoint-up(md) {
                  right: 65px;
                  bottom: 0;
                }
              }
            }
          }
        }
      }
    }

    .secSchool__course {
      .courseWrap {
        @include media-breakpoint-up(md) {
          display: inline-flex;
        }

        .course {
          &__container {
            @include media-breakpoint-up(md) {
              padding: 70px 75px 90px 125px;
            }
          }

          &:nth-child(odd) {
            &::before {
              @include media-breakpoint-up(md) {
                top: 20px;
                left: calc(50% - 370px);
                width: 180px;
                height: 210px;
                background: url('/assets/images/pages/index/school_course_bg-deco3-1_md.svg')
                  no-repeat center/contain;
              }
            }

            &::after {
              @include media-breakpoint-up(md) {
                top: 35px;
                left: calc(50% + 175px);
                width: 164px;
                height: 209px;
                background: url('/assets/images/pages/index/school_course_bg-deco3-2_md.svg')
                  no-repeat center/contain;
              }
            }

            .course__container {
              &::before {
                @include media-breakpoint-up(md) {
                  bottom: 32px;
                  left: calc(50% - 335px);
                  width: 180px;
                  height: 228px;
                  background: url('/assets/images/pages/index/school_course_bg-deco3-3_md.svg')
                    no-repeat center/contain;
                }
              }
            }
          }

          &:nth-child(even) {
            &::before {
              @include media-breakpoint-up(md) {
                top: -50px;
                left: calc(50% + 85px);
                width: 258px;
                height: 240px;
                background: url('/assets/images/pages/index/school_course_bg-deco4-1_md.svg')
                  no-repeat center/contain;
              }
            }

            &::after {
              @include media-breakpoint-up(md) {
                top: auto;
                bottom: 150px;
                left: calc(50% - 390px);
                width: 219px;
                height: 256px;
                background: url('/assets/images/pages/index/school_course_bg-deco4-2_md.svg')
                  no-repeat center/contain;
              }
            }

            .course__container {
              &::before {
                @include media-breakpoint-up(md) {
                  bottom: 155px;
                  left: calc(50% + 155px);
                  width: 261px;
                  height: 244px;
                  background: url('/assets/images/pages/index/school_course_bg-deco4-3_md.svg')
                    no-repeat center/contain;
                }
              }
            }
          }
        }
      }

      .course {
        @include media-breakpoint-up(md) {
          width: 640px;
        }
      }
    }

    .secSchool__bnr {
      --bnrBg: linear-gradient(90deg, #e03fad, #b72f8c);

      .bnr {
        &__container {
          border-image-source: url('/assets/images/pages/common/compornent_border-frame-pink.png');
        }

        &__inner {
          &::before {
            background: #fad9ea;
          }
        }
      }
    }
  }

  &--headReverse {
    .secSchool__head {
      @include media-breakpoint-up(md) {
        flex-direction: row-reverse;
      }
    }
  }
}

// animation

.secSchool .secSchool__head .headContents .contentsHdg {
  .chara {
    display: inline-block;
    opacity: 0;
    transform: rotateY(-90deg);
    backface-visibility: hidden;
  }

  &.is-active {
    .chara {
      $item-length: 6;

      opacity: 1;
      transform: rotateY(0);

      @for $i from 1 through $item-length {
        &--#{$i} {
          transition: transform 1.5s $ease-out-sine, opacity 0.5s $ease-out-sine;
          transition-delay: 0.1s * $i;
        }
      }
    }
  }
}

.secSchool .secSchool__head .headContents .contentsImg,
.secSchool .secSchool__head .headContents .contentsLead,
.secSchool .secSchool__head .headContents .contentsTxt,
.secSchool .secSchool__head .headContents .contentsLinkList {
  opacity: 0;

  &.is-active {
    opacity: 1;
    transition: opacity 0.5s $ease-out-sine;
  }
}

.secSchool .secSchool__course .course {
  .visualImg,
  .visualIcon {
    opacity: 0;
  }

  .visualHdg {
    &__main {
      > h3::before {
        height: 0;
      }

      > h3 .chara {
        opacity: 0;
      }
    }

    &__sub {
      > p::before {
        height: 0;
      }

      > p .chara {
        opacity: 0;
      }
    }
  }

  .course__txt {
    opacity: 0;
  }

  &.is-active {
    $titleSubDelay: 0.3s;
    $textDelay: 0.3s;

    .visualImg,
    .visualIcon {
      opacity: 1;
      transition: opacity 0.4s $ease-out-sine;
    }

    .visualHdg {
      &__main {
        > h3::before {
          height: 100%;
          transition: height 0.8s $ease-out-cubic,
            background-color 0.4s $ease-out-sine;
        }

        > h3 .chara {
          opacity: 1;

          @for $i from 1 through 20 {
            &--#{$i} {
              transition: transform 1s $ease-out-sine,
                opacity 0.4s $ease-out-sine;
              transition-delay: $textDelay + 0.08s * $i;
            }
          }
        }
      }

      &__sub {
        > p::before {
          height: 100%;
          transition: height 1s $ease-out-cubic $titleSubDelay,
            background-color 0.4s $ease-out-sine;
        }

        > p .chara {
          opacity: 1;

          @for $i from 1 through 50 {
            &--#{$i} {
              transition: opacity 0.4s $ease-out-sine;
              transition-delay: $titleSubDelay + $textDelay + 0.08s * $i;
            }
          }
        }

        &--long {
          > p .chara {
            @for $i from 1 through 50 {
              &--#{$i} {
                transition-delay: $titleSubDelay + $textDelay + 0.02s * $i;
              }
            }
          }
        }
      }
    }

    .course__txt {
      opacity: 1;
      transition: opacity 0.4s $ease-out-sine $titleSubDelay + 0.4s;
    }
  }
}

.secSchool .secSchool__bnr .bnr {
  opacity: 0;

  &.is-active {
    opacity: 1;
    transition: opacity 0.4s $ease-out-sine;

    &:nth-child(2) {
      @include media-breakpoint-up(md) {
        transition-delay: 0.2s;
      }
    }
  }
}
