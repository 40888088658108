.importantAnnouncement {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 6px 24px 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.23;
  color: #f2c94c;
  letter-spacing: 0;
  background: #000;

  &[href] {
    @include a-hover-fade();
  }

  &__svg {
    min-width: 18px;
    min-height: 18px;
    margin-right: 7px;
  }

  + .l-header {
    .l-header__head {
      padding-top: 55px;
    }
  }
}
