.secAbout {
  padding: 55px 0 125px;
  overflow: hidden;
  background: #fcf1f1;

  @include media-breakpoint-up(md) {
    position: relative;
    padding: 100px 0 230px;
  }

  &__object {
    position: absolute;

    &--1 {
      top: -32px;
      left: 88px;
    }

    &--2 {
      top: 33px;
      right: -18px;
    }

    &--3 {
      bottom: 490px;
      left: -100px;
    }

    &--4 {
      right: -107px;
      bottom: 12px;
    }
  }

  &__hdg {
    text-align: center;
  }

  &__body {
    margin: 35px 0 0;

    @include media-breakpoint-up(md) {
      margin: 55px 0 0;
    }
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0;
      justify-content: space-between;
      width: 1146px;
      margin: -55px auto 0;
    }

    &::before,
    &::after {
      position: absolute;
      z-index: 6;
      pointer-events: none;
      content: '';
      background: no-repeat center/contain;
    }

    &::before {
      top: -60px;
      right: -20px;
      width: 186px;
      height: 220px;
      background-image: url('/assets/images/pages/index/about_deco1.svg');

      @include media-breakpoint-up(md) {
        top: -170px;
        right: -165px;
        width: 615px;
        height: 656px;
        background-image: url('/assets/images/pages/index/about_deco1_md.svg');
      }
    }

    &::after {
      right: -10px;
      bottom: -100px;
      width: 252px;
      height: 196px;
      background-image: url('/assets/images/pages/index/about_deco2.svg');

      @include media-breakpoint-up(md) {
        right: -85px;
        bottom: -15px;
        width: 725px;
        height: 675px;
        background-image: url('/assets/images/pages/index/about_deco2_md.svg');
      }
    }

    .aboutItem {
      position: relative;
      z-index: 0;
      width: 289px;
      height: 244px;

      @include media-breakpoint-up(md) {
        z-index: 0;
        width: 547px;
        height: 464px;
        margin: 55px 0 0;
      }

      @include hover() {
        .aboutItem__typography {
          filter: brightness(1000%);
        }

        .aboutItem__bg {
          .fill-white {
            fill: $color-baika-pink;
          }
        }

        .aboutItem__deco {
          path {
            fill: $color-baika-pink !important; // stylelint-disable declaration-no-important
          }
        }
      }

      &::before,
      &::after {
        position: absolute;
        z-index: -1;
        pointer-events: none;
        content: '';
        background: no-repeat center/contain;
      }

      &__typography {
        position: absolute;
        top: 20px;
        left: 10px;
        z-index: 1;
        transition: filter 0.4s $ease-out-sine;

        @include media-breakpoint-up(md) {
          left: 30px;
        }
      }

      &__bg {
        .fill-white {
          fill: #fff;
          transition: fill 0.4s $ease-out-sine;
        }
      }

      &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 9px 11px 0;

        @include media-breakpoint-up(md) {
          padding: 18px 21px 0;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      &__deco {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        path {
          transition: fill 0.4s $ease-out-sine;
        }
      }

      &__ttl {
        position: absolute;
        bottom: 10px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 55px;
        padding: 0 0 5px;
        font-family: $font-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.2;
        color: #fff;
        text-align: center;
        letter-spacing: 0;

        @include media-breakpoint-up(md) {
          bottom: 20px;
          height: 105px;
          padding: 0 0 25px;
          font-size: 30px;
        }
      }

      &:nth-child(even) {
        margin: 0 0 0 auto;

        @include media-breakpoint-up(md) {
          margin: 55px 0 0;
          transform: translateY(105px);
        }
      }

      &--feature {
        z-index: 4;

        &::before {
          top: 94px;
          right: -60px;
          width: 102px;
          height: 131px;
          background-image: url('/assets/images/pages/index/about_item-bg-deco1-1.svg');

          @include media-breakpoint-up(md) {
            top: 32px;
            right: -87px;
            width: 195px;
            height: 249px;
          }
        }
      }

      &--step {
        z-index: 3;

        &::before {
          top: 104px;
          right: -80px;
          width: 194px;
          height: 168px;
          background-image: url('/assets/images/pages/index/about_item-bg-deco2-1.svg');

          @include media-breakpoint-up(md) {
            top: 248px;
            right: -66px;
            width: 289px;
            height: 250px;
          }
        }
      }

      &--international {
        z-index: 2;

        &::before {
          top: -148px;
          left: -72px;
          width: 239px;
          height: 225px;
          background-image: url('/assets/images/pages/index/about_item-bg-deco3-1.svg');

          @include media-breakpoint-up(md) {
            top: -182px;
            left: -160px;
            width: 350px;
            height: 329px;
          }
        }

        &::after {
          top: 10px;
          right: -55px;
          width: 163px;
          height: 137px;
          background-image: url('/assets/images/pages/index/about_item-bg-deco3-2.svg');

          @include media-breakpoint-up(md) {
            top: 16px;
            right: -106px;
            width: 310px;
            height: 260px;
          }
        }
      }

      &--openCampus {
        z-index: 1;

        &::before {
          @include media-breakpoint-up(md) {
            top: -24px;
            right: -169px;
            width: 317px;
            height: 275px;
            background-image: url('/assets/images/pages/index/about_item-bg-deco4-1.svg');
          }
        }
      }

      &--schoolTripTimeline {
        z-index: 0;

        &::before {
          top: 125px;
          left: -58px;
          width: 160px;
          height: 138px;
          background-image: url('/assets/images/pages/index/about_item-bg-deco5-1.svg');

          @include media-breakpoint-up(md) {
            top: 237px;
            left: -109px;
            width: 303px;
            height: 263px;
          }
        }

        &::after {
          top: -83px;
          right: -107px;
          width: 223px;
          height: 217px;
          background-image: url('/assets/images/pages/index/about_item-bg-deco5-2.svg');

          @include media-breakpoint-up(md) {
            top: -131px;
            right: -48px;
            width: 327px;
            height: 318px;
          }
        }
      }
    }
  }
}

// animation

.secAbout .secAbout__hdg {
  opacity: 0;

  &.is-active {
    opacity: 1;
    transition: opacity 0.4s $ease-out-sine;
  }
}

.secAbout .secAbout__inner .aboutItem {
  opacity: 0;

  &.is-active {
    opacity: 1;
    transition: opacity 0.4s $ease-out-sine;
  }
}
