.m-popup {
  position: fixed;
  right: 68px;
  bottom: 0;
  z-index: 9999;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.m-popup__txt {
  max-width: 100%;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  -ms-word-break: break-all;
  word-break: break-all;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media all and (max-width: 767px) {
  .m-popup {
    right: 30px;
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  }

  .m-popup__txt {
    font-size: 18px;
  }
}

.m-popup__close {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
  width: 30px;
  height: 30px;
  opacity: 0;
}

@media all and (max-width: 767px) {
  .m-popup__close {
    width: 40px;
    height: 40px;
  }
}

.m-popup__close span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: #fff;
  border: 1px solid #f75f8d;
  border-radius: 50%;
}

.m-popup__close span:hover {
  opacity: 0.9;
}

.m-popup__close span::before,
.m-popup__close span::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 60%;
  height: 1px;
  margin: auto;
  content: '';
  background: #f75f8d;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.m-popup__close span::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media all and (max-width: 767px) {
  .m-popup__close span {
    border-width: 2px;
  }

  .m-popup__close span::before,
  .m-popup__close span::after {
    height: 2px;
  }
}

.m-popup a {
  display: block;
}

.m-popup a:hover {
  opacity: 0.9;
}

.m-popup img {
  max-width: none;
}

/* plane
====================================================================== */
.m-popup-plane img {
  max-width: 290px;
  height: auto;
}

@media all and (max-width: 767px) {
  .m-popup-plane img {
    max-width: 164px;
  }
}

/* anim
---------------------------------------------------------------------- */
.m-popup-plane a {
  -webkit-transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transform: translateY(110%);
  -ms-transform: translateY(110%);
  transform: translateY(110%);
}

.m-popup-plane .m-popup__close {
  -webkit-transition: 0.5s ease 1s;
  -o-transition: 0.5s ease 1s;
  transition: 0.5s ease 1s;
}

/* active */
.m-popup-plane.active a {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.m-popup-plane.active .m-popup__close {
  opacity: 1;
}

/* stage
====================================================================== */
.m-popup-stage__base {
  position: relative;
  width: 328px;
  height: 251px;
  overflow: hidden;
  background: url(/shared/images/popup/stage/base.png) no-repeat 50% 50%;
  background-size: cover;
  border-radius: 8px 8px 0 0;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
}

.m-popup-stage__panel {
  position: absolute;
  top: 105px;
  right: 0;
  left: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: center;
  justify-content: center;
  width: 227px;
  height: 162px;
  padding: 20px;
  margin: auto;
  text-align: center;
  background: #fff url(/shared/images/popup/stage/bg.png) no-repeat 50% 50%;
  background-size: cover;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.m-popup-stage__curtain__b-l,
.m-popup-stage__curtain__b-r,
.m-popup-stage__curtain__f-l,
.m-popup-stage__curtain__f-r {
  position: absolute;
  top: 0;
}

.m-popup-stage__curtain__b-r img,
.m-popup-stage__curtain__f-r img {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

.m-popup-stage__curtain__b-l {
  right: 260px;
}

.m-popup-stage__curtain__b-r {
  left: 260px;
}

.m-popup-stage__curtain__f-l {
  right: 275px;
}

.m-popup-stage__curtain__f-r {
  left: 275px;
}

.m-popup-stage__pole {
  position: absolute;
  top: 2px;
  right: 0;
  left: 0;
  width: 320px;
  height: 45px;
  margin: auto;
  overflow: hidden;
  background: url(/shared/images/popup/stage/pole.png) no-repeat 50% 0;
  background-size: contain;
}

.m-popup-stage__pole__curtain-l,
.m-popup-stage__pole__curtain-r,
.m-popup-stage__pole__curtain-c {
  position: absolute;
  top: 0;
}

.m-popup-stage__pole__curtain-l {
  left: 7px;
}

.m-popup-stage__pole__curtain-r {
  right: 7px;
}

.m-popup-stage__pole__curtain-c {
  right: 0;
  left: 0;
  text-align: center;
}

.m-popup-stage__pole__curtain-r img {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

.m-popup-stage__light-l,
.m-popup-stage__light-r {
  position: absolute;
  top: 0;
}

.m-popup-stage__light-l {
  left: 42px;
}

.m-popup-stage__light-r {
  right: 42px;
}

.m-popup-stage__light-r img {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

/* anim
---------------------------------------------------------------------- */

/* 全体 */
.m-popup-stage {
  perspective: 1000px;
}

.m-popup-stage__base {
  opacity: 0;
  -webkit-transform: rotateX(100deg);
  -ms-transform: rotateX(100deg);
  transform: rotateX(100deg);
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

/* ポール */
.m-popup-stage__pole {
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}

.m-popup-stage__pole__curtain-l {
  -webkit-transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.25s;
  -o-transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.25s;
  transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.25s;
  -webkit-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  transform: rotate(-60deg);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
}

.m-popup-stage__pole__curtain-r {
  -webkit-transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.15s;
  -o-transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.15s;
  transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.15s;
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  -o-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.m-popup-stage__pole__curtain-c {
  -webkit-transition: 1s linear 2.8s;
  -o-transition: 1s linear 2.8s;
  transition: 1s linear 2.8s;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

/* 前のカーテン */
.m-popup-stage__curtain__b-l {
  right: 115px;
  transition: 2s linear 2.5s;
}

.m-popup-stage__curtain__b-r {
  left: 115px;
  transition: 2s linear 2.5s;
}

.m-popup-stage__curtain__f-l {
  right: 115px;
  transition: 2s linear 2s;
}

.m-popup-stage__curtain__f-r {
  left: 115px;
  transition: 2s linear 2s;
}

/* パネル */
.m-popup-stage__panel {
  top: -162px;
}

.m-popup-stage__light-l,
.m-popup-stage__light-r {
  -webkit-transition: 1s linear 4.6s;
  -o-transition: 1s linear 4.6s;
  transition: 1s linear 4.6s;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.m-popup-stage .m-popup__close {
  transition: 0.5s 6.5s;
}

/* active */
.m-popup-stage.active .m-popup-stage__base {
  opacity: 1;
  -webkit-animation: stage-getup 1s ease forwards;
  animation: stage-getup 1s ease forwards;
}

@keyframes stage-getup {
  30% {
    -webkit-transform: rotateX(-13deg);
    -ms-transform: rotateX(-13deg);
    transform: rotateX(-13deg);
  }

  40% {
    -webkit-transform: rotateX(3deg);
    -ms-transform: rotateX(3deg);
    transform: rotateX(3deg);
  }

  50% {
    -webkit-transform: rotateX(-10deg);
    -ms-transform: rotateX(-10deg);
    transform: rotateX(-10deg);
  }

  60% {
    -webkit-transform: rotateX(2deg);
    -ms-transform: rotateX(2deg);
    transform: rotateX(2deg);
  }

  70% {
    -webkit-transform: rotateX(-5deg);
    -ms-transform: rotateX(-5deg);
    transform: rotateX(-5deg);
  }

  80% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }

  100% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}

.m-popup-stage.active .m-popup-stage__pole {
  -webkit-animation: stage-pole 1s ease 1s forwards;
  animation: stage-pole 1s ease 1s forwards;
}

@keyframes stage-pole {
  30% {
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
  }

  30% {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }

  46% {
    -webkit-transform: scaleX(0.94);
    -ms-transform: scaleX(0.94);
    transform: scaleX(0.94);
  }

  60% {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }

  73% {
    -webkit-transform: scaleX(0.96);
    -ms-transform: scaleX(0.96);
    transform: scaleX(0.96);
  }

  86% {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }

  100% {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.m-popup-stage.active .m-popup-stage__pole__curtain-l,
.m-popup-stage.active .m-popup-stage__pole__curtain-r {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.m-popup-stage.active .m-popup-stage__pole__curtain-c {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.m-popup-stage.active .m-popup-stage__curtain__b-l {
  right: 260px;
}

.m-popup-stage.active .m-popup-stage__curtain__b-r {
  left: 260px;
}

.m-popup-stage.active .m-popup-stage__curtain__f-l {
  right: 275px;
}

.m-popup-stage.active .m-popup-stage__curtain__f-r {
  left: 275px;
}

.m-popup-stage.active .m-popup-stage__panel {
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-animation: stage-panel 1s linear 3.4s forwards;
  animation: stage-panel 1s linear 3.4s forwards;
}

@keyframes stage-panel {
  50% {
    top: 62px;
    -webkit-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  70% {
    top: 17px;
    -webkit-transform: rotate(-4deg);
    -ms-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }

  85% {
    top: 62px;
    -webkit-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    transform: rotate(2deg);
  }

  93% {
    top: 45px;
    -webkit-transform: rotate(-0.5deg);
    -ms-transform: rotate(-0.5deg);
    transform: rotate(-0.5deg);
  }

  100% {
    top: 52px;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
}

.m-popup-stage.active .m-popup-stage__light-l,
.m-popup-stage.active .m-popup-stage__light-r {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.m-popup-stage.active .m-popup__close {
  opacity: 1;
}

/* book
====================================================================== */
.m-popup-book__base {
  position: relative;
  width: 328px;
  height: 200px;
  perspective: 500px;
}

.m-popup-book__right {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 163px;
  height: 201px;
  background: url(/shared/images/popup/book/book_r.png) no-repeat 100% 100%;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
}

.m-popup-book__left {
  position: absolute;
  right: 163px;
  bottom: 0;
  width: 164px;
  height: 200px;
  perspective: 700px;
}

.m-popup-book__left__front,
.m-popup-book__left__back {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.m-popup-book__left__front {
  left: 100%;
  background: url(/shared/images/popup/book/cover.png) no-repeat 0% 100%;
  -webkit-transform-origin: 0% 100%;
  -moz-transform-origin: 0% 100%;
  -ms-transform-origin: 0% 100%;
  -o-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
}

.m-popup-book__left__back {
  left: 0;
  background: url(/shared/images/popup/book/book_l.png) no-repeat 100% 100%;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
  -webkit-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.m-popup-book__paper {
  position: absolute;
  right: 0;
  bottom: 50px;
  left: 0;
  width: 252px;
  height: 184px;
  margin: auto;
  background: url(/shared/images/popup/book/textarea.png) no-repeat 50% 50%;
}

.m-popup-book__paper .m-popup__txt {
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: center;
  justify-content: center;
  width: 200px;
  height: 130px;
  margin: auto;
  text-align: center;
}

.m-popup-book .m-popup__close span {
  border-color: #2b3c5d;
}

.m-popup-book .m-popup__close span::before,
.m-popup-book .m-popup__close span::after {
  background: #2b3c5d;
}

/* anim
---------------------------------------------------------------------- */
.m-popup-book__base {
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.m-popup-book__left__front,
.m-popup-book__left__back {
  -webkit-transition: 3s cubic-bezier(0.645, 0.045, 0.355, 1) 1.5s;
  -o-transition: 3s cubic-bezier(0.645, 0.045, 0.355, 1) 1.5s;
  transition: 3s cubic-bezier(0.645, 0.045, 0.355, 1) 1.5s;
}

.m-popup-book__paper {
  opacity: 0;
  -webkit-transform: rotateX(-90deg);
  -ms-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.m-popup-book .m-popup__close {
  -webkit-transition: 0.5s ease 5s;
  -o-transition: 0.5s ease 5s;
  transition: 0.5s ease 5s;
}

.m-popup-book.active .m-popup-book__left__front {
  -webkit-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.m-popup-book.active .m-popup-book__left__back {
  -webkit-transform: rotateY(0);
  -ms-transform: rotateY(0);
  transform: rotateY(0);
}

/* active */
.m-popup-book.active .m-popup-book__base {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.m-popup-book.active .m-popup-book__paper {
  -webkit-animation: book-getup 1.5s ease forwards 3.5s;
  animation: book-getup 1.5s ease forwards 3.5s;
}

@keyframes book-getup {
  0% {
    opacity: 1;
  }

  30% {
    -webkit-transform: rotateX(0);
    -ms-transform: rotateX(0);
    transform: rotateX(0);
  }

  40% {
    -webkit-transform: rotateX(-12deg);
    -ms-transform: rotateX(-12deg);
    transform: rotateX(-12deg);
  }

  50% {
    -webkit-transform: rotateX(0);
    -ms-transform: rotateX(0);
    transform: rotateX(0);
  }

  60% {
    -webkit-transform: rotateX(-6deg);
    -ms-transform: rotateX(-6deg);
    transform: rotateX(-6deg);
  }

  70% {
    -webkit-transform: rotateX(0);
    -ms-transform: rotateX(0);
    transform: rotateX(0);
  }

  80% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}

.m-popup-book.active .m-popup__close {
  opacity: 1;
}

/* oc
====================================================================== */
.m-popup-oc__tree {
  position: relative;
  z-index: -1;
}

.m-popup-oc__stage {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 315px;
  height: 46px;
  margin: auto;
  background: url(/shared/images/popup/oc/stage.png) no-repeat 50% 100%;
}

.m-popup-oc__student {
  position: absolute;
  bottom: 33px;
}

.m-popup-oc__student::before {
  position: absolute;
  bottom: 0;
  z-index: 0;
  display: block;
  width: 31px;
  height: 9px;
  content: '';
  background: url(/shared/images/popup/oc/hole.png) no-repeat 50% 50%;
}

.m-popup-oc__student img {
  position: relative;
  z-index: 1;
}

.m-popup-oc__student01 {
  left: 24px;
  padding-left: 6px;
}

.m-popup-oc__student01::before {
  left: 0;
}

.m-popup-oc__student02 {
  right: 8px;
}

.m-popup-oc__student02::before {
  right: 15px;
}

.m-popup-oc__panel {
  position: absolute;
  right: 0;
  bottom: 41px;
  left: 0;
  z-index: 0;
  width: 240px;
  height: 154px;
  margin: auto;
  text-align: center;
  background: url(/shared/images/popup/oc/panel.png) no-repeat 50% 50%;
}

.m-popup-oc__panel .m-popup__txt {
  position: absolute;
  top: 11px;
  right: 0;
  left: 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: center;
  justify-content: center;
  width: 200px;
  height: 120px;
  margin: auto;
}

.m-popup-oc__panel__deco {
  position: absolute;
  top: -6px;
  left: -6px;
  z-index: 1;
}

.m-popup-oc__panel__deco:nth-child(2) {
  right: -6px;
  left: auto;
}

.m-popup-oc__panel__deco:nth-child(2) img {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

.m-popup-oc .m-popup__close {
  top: 0;
  right: 30px;
  z-index: 10;
}

/* anim
---------------------------------------------------------------------- */
.m-popup-oc {
  perspective: 500px;
}

.m-popup-oc__tree {
  opacity: 0;
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.m-popup-oc__stage {
  -webkit-transition: 0.5s linear 0.4s;
  -o-transition: 0.5s linear 0.4s;
  transition: 0.5s linear 0.4s;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.m-popup-oc__student {
  padding-top: 60px;
  overflow: hidden;
}

.m-popup-oc__student::before {
  -webkit-transition: 0.2s linear 0.9s;
  -o-transition: 0.2s linear 0.9s;
  transition: 0.2s linear 0.9s;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}

.m-popup-oc__student img {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.m-popup-oc__student02 {
  right: 8px;
}

.m-popup-oc__panel {
  opacity: 0;
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.m-popup-oc__panel__deco {
  opacity: 0;
  -webkit-transition: 0.7s ease 2.83s;
  -o-transition: 0.7s ease 2.83s;
  transition: 0.7s ease 2.83s;
  -webkit-transform: translateY(-20%);
  -ms-transform: translateY(-20%);
  transform: translateY(-20%);
}

.m-popup-oc .m-popup__close {
  -webkit-transition: 0.5s ease 3.8s;
  -o-transition: 0.5s ease 3.8s;
  transition: 0.5s ease 3.8s;
}

/* active */
.m-popup-oc.active .m-popup-oc__tree {
  -webkit-animation: tree-getup 1.3s ease forwards;
  animation: tree-getup 1.3s ease forwards;
}

@keyframes tree-getup {
  0% {
    opacity: 1;
  }

  26% {
    -webkit-transform: rotateX(-13deg);
    -ms-transform: rotateX(-13deg);
    transform: rotateX(-13deg);
  }

  36% {
    -webkit-transform: rotateX(5deg);
    -ms-transform: rotateX(5deg);
    transform: rotateX(5deg);
  }

  46% {
    -webkit-transform: rotateX(-4deg);
    -ms-transform: rotateX(-4deg);
    transform: rotateX(-4deg);
  }

  56% {
    -webkit-transform: rotateX(3deg);
    -ms-transform: rotateX(3deg);
    transform: rotateX(3deg);
  }

  68% {
    -webkit-transform: rotateX(-2deg);
    -ms-transform: rotateX(-2deg);
    transform: rotateX(-2deg);
  }

  75% {
    -webkit-transform: rotateX(1deg);
    -ms-transform: rotateX(1deg);
    transform: rotateX(1deg);
  }

  83% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}

.m-popup-oc.active .m-popup-oc__stage {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.m-popup-oc.active .m-popup-oc__student::before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.m-popup-oc.active .m-popup-oc__student img {
  -webkit-animation: jump 1s linear 1.4s forwards;
  animation: jump 1s linear 1.4s forwards;
}

@keyframes jump {
  50% {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  60% {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  82% {
    -webkit-transform: translateY(2%);
    -ms-transform: translateY(2%);
    transform: translateY(2%);
  }

  91% {
    -webkit-transform: translateY(-6%);
    -ms-transform: translateY(-6%);
    transform: translateY(-6%);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.m-popup-oc.active .m-popup-oc__panel {
  -webkit-animation: oc-getup 1s ease 1.73s forwards;
  animation: oc-getup 1s ease 1.73s forwards;
}

@keyframes oc-getup {
  0% {
    opacity: 1;
  }

  60% {
    -webkit-transform: rotateX(-13deg);
    -ms-transform: rotateX(-13deg);
    transform: rotateX(-13deg);
  }

  70% {
    -webkit-transform: rotateX(5deg);
    -ms-transform: rotateX(5deg);
    transform: rotateX(5deg);
  }

  80% {
    -webkit-transform: rotateX(-4deg);
    -ms-transform: rotateX(-4deg);
    transform: rotateX(-4deg);
  }

  90% {
    -webkit-transform: rotateX(3deg);
    -ms-transform: rotateX(3deg);
    transform: rotateX(3deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}

.m-popup-oc.active .m-popup-oc__panel__deco {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.m-popup-oc.active .m-popup__close {
  opacity: 1;
}
