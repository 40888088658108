.bnrContents {
  padding: 25px 15px;
  overflow: hidden;
  background: linear-gradient(90deg, #e76398, #e52d8a);

  .swiper-container {
    margin: 0 -8px;
    overflow: visible;

    @include media-breakpoint-up(md) {
      margin: 0 -12px;
    }
  }

  .swiper-wrapper {
    @include media-breakpoint-up(md) {
      justify-content: center;
    }
  }

  .swiper-slide {
    box-sizing: border-box;
    width: 251px;
    height: 82px;
    padding: 0 8px;

    @include media-breakpoint-up(md) {
      width: 259px;
      padding: 0 12px;
    }

    .bnr {
      @include a-hover-fade();

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    right: 0;
    margin: -25px 0 0;
  }
}
