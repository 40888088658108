@charset "UTF-8";
/* stylelint-disable */
/* stylelint-enable */
.bnrContents {
  padding: 25px 15px;
  overflow: hidden;
  background: linear-gradient(90deg, #e76398, #e52d8a);
}
.bnrContents .swiper-container {
  margin: 0 -8px;
  overflow: visible;
}
@media (min-width: 768px) {
  .bnrContents .swiper-container {
    margin: 0 -12px;
  }
}
@media (min-width: 768px) {
  .bnrContents .swiper-wrapper {
    justify-content: center;
  }
}
.bnrContents .swiper-slide {
  box-sizing: border-box;
  width: 251px;
  height: 82px;
  padding: 0 8px;
}
@media (min-width: 768px) {
  .bnrContents .swiper-slide {
    width: 259px;
    padding: 0 12px;
  }
}
.bnrContents .swiper-slide .bnr {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
@media (hover: hover) and (pointer: fine) {
  .bnrContents .swiper-slide .bnr:hover {
    opacity: 0.6;
  }
}
.bnrContents .swiper-slide .bnr img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.bnrContents .swiper-button-prev,
.bnrContents .swiper-button-next {
  right: 0;
  margin: -25px 0 0;
}

.importantAnnouncement {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 6px 24px 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.23;
  color: #f2c94c;
  letter-spacing: 0;
  background: #000;
}
.importantAnnouncement[href] {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
@media (hover: hover) and (pointer: fine) {
  .importantAnnouncement[href]:hover {
    opacity: 0.6;
  }
}
.importantAnnouncement__svg {
  min-width: 18px;
  min-height: 18px;
  margin-right: 7px;
}
.importantAnnouncement + .l-header .l-header__head {
  padding-top: 55px;
}

.mainVisual {
  position: relative;
}
@media (min-width: 768px) {
  .mainVisual__bg {
    display: flex;
  }
}
.mainVisual__bg .bg {
  position: relative;
}
@media (min-width: 768px) {
  .mainVisual__bg .bg {
    width: 50%;
  }
}
.mainVisual__bg .bg::before {
  position: absolute;
  content: "";
  background: no-repeat center top/contain;
  mix-blend-mode: overlay;
}
.mainVisual__bg .bg--1::before {
  top: 60px;
  left: 4px;
  width: 58px;
  height: 224px;
  background-image: url("/assets/images/pages/index/mv_bg-typography1.svg");
}
@media (min-width: 768px) {
  .mainVisual__bg .bg--1::before {
    top: 113px;
    left: 10px;
    width: 133px;
    height: 546px;
    background-image: url("/assets/images/pages/index/mv_bg-typography1_md.svg");
  }
}
.mainVisual__bg .bg--2::before {
  right: 7px;
  bottom: 11px;
  width: 31px;
  height: 215px;
  background-image: url("/assets/images/pages/index/mv_bg-typography2.svg");
}
@media (min-width: 768px) {
  .mainVisual__bg .bg--2::before {
    top: 119px;
    right: 15px;
    width: 72px;
    height: 525px;
    background-image: url("/assets/images/pages/index/mv_bg-typography2_md.svg");
  }
}
.mainVisual__bg .bg img {
  width: 100%;
  max-height: 100vh;
  object-fit: cover;
}
.mainVisual__contents {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .mainVisual__contents {
    min-width: 1280px;
  }
}
.mainVisual__contents .hdg {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
}
.mainVisual__contents .hdg__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 359px;
  height: 93px;
}
@media (min-width: 768px) {
  .mainVisual__contents .hdg__inner {
    width: 177px;
    height: 625px;
  }
}
.mainVisual__contents .hdg__frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.mainVisual__contents .hdg__frame::before, .mainVisual__contents .hdg__frame::after {
  position: absolute;
  width: 34px;
  height: 34px;
  content: "";
  background: #fff no-repeat center/contain;
  mask-image: url("/assets/images/pages/common/compornent_corner-frame.svg");
}
@media (min-width: 768px) {
  .mainVisual__contents .hdg__frame::before, .mainVisual__contents .hdg__frame::after {
    width: 58px;
    height: 58px;
  }
}
.mainVisual__contents .hdg__frame--top::before, .mainVisual__contents .hdg__frame--top::after {
  top: 0;
}
.mainVisual__contents .hdg__frame--top::before {
  left: 0;
}
.mainVisual__contents .hdg__frame--top::after {
  right: 0;
  transform: rotate(90deg);
}
.mainVisual__contents .hdg__frame--bottom::before, .mainVisual__contents .hdg__frame--bottom::after {
  bottom: 0;
}
.mainVisual__contents .hdg__frame--bottom::before {
  left: 0;
  transform: rotate(270deg);
}
.mainVisual__contents .hdg__frame--bottom::after {
  right: 0;
  transform: rotate(180deg);
}
.mainVisual__contents .hdg__txt {
  font-family: "Zen Old Mincho", serif;
  font-size: 36px;
  color: #e52d8a;
  text-shadow: 0 0 34px #fff;
  letter-spacing: -0.1em;
}
@media (min-width: 768px) {
  .mainVisual__contents .hdg__txt {
    font-size: 56px;
    letter-spacing: 0;
    writing-mode: vertical-rl;
  }
}
.mainVisual__contents .news {
  position: absolute;
  right: 45px;
  bottom: 25px;
  left: 0;
  display: block;
  padding: 10px 15px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0 8px 8px 0;
}
@media (min-width: 768px) {
  .mainVisual__contents .news {
    right: auto;
    width: 432px;
    padding: 15px 15px 15px 25px;
  }
}
.mainVisual__contents .news__head {
  display: flex;
  align-items: center;
}
.mainVisual__contents .news__date {
  font-size: 14px;
  font-weight: 400;
  color: #828282;
  letter-spacing: 0;
}
.mainVisual__contents .news__label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 6px;
  margin: 0 0 0 5px;
  font-size: 12px;
  font-weight: 700;
  color: #e52d8a;
  background: rgba(229, 45, 138, 0.14);
  border-radius: 12px;
}
@media (min-width: 768px) {
  .mainVisual__contents .news__label {
    padding: 2px 12px;
    margin: 0 0 0 15px;
    font-size: 13px;
  }
}
.mainVisual__contents .news__txt {
  margin: 5px 0 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .mainVisual__contents .news__txt {
    font-size: 16px;
  }
}

.opening {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: #000;
}
.opening__mask {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
}
.opening__mask .mask {
  width: 50%;
  height: 100%;
  background: #0f0;
}
.opening__mask .mask + .mask {
  background: #f00;
}

.l-page {
  background: #fcf1f1 url("/assets/images/pages/index/repeat-bg_diary.png") repeat center/93px auto;
}

.m-popup {
  position: fixed;
  right: 68px;
  bottom: 0;
  z-index: 9999;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.m-popup__txt {
  max-width: 100%;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  -ms-word-break: break-all;
  word-break: break-all;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media all and (max-width: 767px) {
  .m-popup {
    right: 30px;
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  }
  .m-popup__txt {
    font-size: 18px;
  }
}
.m-popup__close {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
  width: 30px;
  height: 30px;
  opacity: 0;
}

@media all and (max-width: 767px) {
  .m-popup__close {
    width: 40px;
    height: 40px;
  }
}
.m-popup__close span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: #fff;
  border: 1px solid #f75f8d;
  border-radius: 50%;
}

.m-popup__close span:hover {
  opacity: 0.9;
}

.m-popup__close span::before,
.m-popup__close span::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 60%;
  height: 1px;
  margin: auto;
  content: "";
  background: #f75f8d;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.m-popup__close span::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media all and (max-width: 767px) {
  .m-popup__close span {
    border-width: 2px;
  }
  .m-popup__close span::before,
  .m-popup__close span::after {
    height: 2px;
  }
}
.m-popup a {
  display: block;
}

.m-popup a:hover {
  opacity: 0.9;
}

.m-popup img {
  max-width: none;
}

/* plane
====================================================================== */
.m-popup-plane img {
  max-width: 290px;
  height: auto;
}

@media all and (max-width: 767px) {
  .m-popup-plane img {
    max-width: 164px;
  }
}
/* anim
---------------------------------------------------------------------- */
.m-popup-plane a {
  -webkit-transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transform: translateY(110%);
  -ms-transform: translateY(110%);
  transform: translateY(110%);
}

.m-popup-plane .m-popup__close {
  -webkit-transition: 0.5s ease 1s;
  -o-transition: 0.5s ease 1s;
  transition: 0.5s ease 1s;
}

/* active */
.m-popup-plane.active a {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.m-popup-plane.active .m-popup__close {
  opacity: 1;
}

/* stage
====================================================================== */
.m-popup-stage__base {
  position: relative;
  width: 328px;
  height: 251px;
  overflow: hidden;
  background: url(/shared/images/popup/stage/base.png) no-repeat 50% 50%;
  background-size: cover;
  border-radius: 8px 8px 0 0;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
}

.m-popup-stage__panel {
  position: absolute;
  top: 105px;
  right: 0;
  left: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: center;
  justify-content: center;
  width: 227px;
  height: 162px;
  padding: 20px;
  margin: auto;
  text-align: center;
  background: #fff url(/shared/images/popup/stage/bg.png) no-repeat 50% 50%;
  background-size: cover;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.m-popup-stage__curtain__b-l,
.m-popup-stage__curtain__b-r,
.m-popup-stage__curtain__f-l,
.m-popup-stage__curtain__f-r {
  position: absolute;
  top: 0;
}

.m-popup-stage__curtain__b-r img,
.m-popup-stage__curtain__f-r img {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

.m-popup-stage__curtain__b-l {
  right: 260px;
}

.m-popup-stage__curtain__b-r {
  left: 260px;
}

.m-popup-stage__curtain__f-l {
  right: 275px;
}

.m-popup-stage__curtain__f-r {
  left: 275px;
}

.m-popup-stage__pole {
  position: absolute;
  top: 2px;
  right: 0;
  left: 0;
  width: 320px;
  height: 45px;
  margin: auto;
  overflow: hidden;
  background: url(/shared/images/popup/stage/pole.png) no-repeat 50% 0;
  background-size: contain;
}

.m-popup-stage__pole__curtain-l,
.m-popup-stage__pole__curtain-r,
.m-popup-stage__pole__curtain-c {
  position: absolute;
  top: 0;
}

.m-popup-stage__pole__curtain-l {
  left: 7px;
}

.m-popup-stage__pole__curtain-r {
  right: 7px;
}

.m-popup-stage__pole__curtain-c {
  right: 0;
  left: 0;
  text-align: center;
}

.m-popup-stage__pole__curtain-r img {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

.m-popup-stage__light-l,
.m-popup-stage__light-r {
  position: absolute;
  top: 0;
}

.m-popup-stage__light-l {
  left: 42px;
}

.m-popup-stage__light-r {
  right: 42px;
}

.m-popup-stage__light-r img {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

/* anim
---------------------------------------------------------------------- */
/* 全体 */
.m-popup-stage {
  perspective: 1000px;
}

.m-popup-stage__base {
  opacity: 0;
  -webkit-transform: rotateX(100deg);
  -ms-transform: rotateX(100deg);
  transform: rotateX(100deg);
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

/* ポール */
.m-popup-stage__pole {
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}

.m-popup-stage__pole__curtain-l {
  -webkit-transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.25s;
  -o-transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.25s;
  transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.25s;
  -webkit-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  transform: rotate(-60deg);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
}

.m-popup-stage__pole__curtain-r {
  -webkit-transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.15s;
  -o-transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.15s;
  transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1) 2.15s;
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  -o-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.m-popup-stage__pole__curtain-c {
  -webkit-transition: 1s linear 2.8s;
  -o-transition: 1s linear 2.8s;
  transition: 1s linear 2.8s;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

/* 前のカーテン */
.m-popup-stage__curtain__b-l {
  right: 115px;
  transition: 2s linear 2.5s;
}

.m-popup-stage__curtain__b-r {
  left: 115px;
  transition: 2s linear 2.5s;
}

.m-popup-stage__curtain__f-l {
  right: 115px;
  transition: 2s linear 2s;
}

.m-popup-stage__curtain__f-r {
  left: 115px;
  transition: 2s linear 2s;
}

/* パネル */
.m-popup-stage__panel {
  top: -162px;
}

.m-popup-stage__light-l,
.m-popup-stage__light-r {
  -webkit-transition: 1s linear 4.6s;
  -o-transition: 1s linear 4.6s;
  transition: 1s linear 4.6s;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.m-popup-stage .m-popup__close {
  transition: 0.5s 6.5s;
}

/* active */
.m-popup-stage.active .m-popup-stage__base {
  opacity: 1;
  -webkit-animation: stage-getup 1s ease forwards;
  animation: stage-getup 1s ease forwards;
}

@keyframes stage-getup {
  30% {
    -webkit-transform: rotateX(-13deg);
    -ms-transform: rotateX(-13deg);
    transform: rotateX(-13deg);
  }
  40% {
    -webkit-transform: rotateX(3deg);
    -ms-transform: rotateX(3deg);
    transform: rotateX(3deg);
  }
  50% {
    -webkit-transform: rotateX(-10deg);
    -ms-transform: rotateX(-10deg);
    transform: rotateX(-10deg);
  }
  60% {
    -webkit-transform: rotateX(2deg);
    -ms-transform: rotateX(2deg);
    transform: rotateX(2deg);
  }
  70% {
    -webkit-transform: rotateX(-5deg);
    -ms-transform: rotateX(-5deg);
    transform: rotateX(-5deg);
  }
  80% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  100% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}
.m-popup-stage.active .m-popup-stage__pole {
  -webkit-animation: stage-pole 1s ease 1s forwards;
  animation: stage-pole 1s ease 1s forwards;
}

@keyframes stage-pole {
  30% {
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
  }
  30% {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
  46% {
    -webkit-transform: scaleX(0.94);
    -ms-transform: scaleX(0.94);
    transform: scaleX(0.94);
  }
  60% {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
  73% {
    -webkit-transform: scaleX(0.96);
    -ms-transform: scaleX(0.96);
    transform: scaleX(0.96);
  }
  86% {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
  100% {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
}
.m-popup-stage.active .m-popup-stage__pole__curtain-l,
.m-popup-stage.active .m-popup-stage__pole__curtain-r {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.m-popup-stage.active .m-popup-stage__pole__curtain-c {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.m-popup-stage.active .m-popup-stage__curtain__b-l {
  right: 260px;
}

.m-popup-stage.active .m-popup-stage__curtain__b-r {
  left: 260px;
}

.m-popup-stage.active .m-popup-stage__curtain__f-l {
  right: 275px;
}

.m-popup-stage.active .m-popup-stage__curtain__f-r {
  left: 275px;
}

.m-popup-stage.active .m-popup-stage__panel {
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-animation: stage-panel 1s linear 3.4s forwards;
  animation: stage-panel 1s linear 3.4s forwards;
}

@keyframes stage-panel {
  50% {
    top: 62px;
    -webkit-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  70% {
    top: 17px;
    -webkit-transform: rotate(-4deg);
    -ms-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  85% {
    top: 62px;
    -webkit-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  93% {
    top: 45px;
    -webkit-transform: rotate(-0.5deg);
    -ms-transform: rotate(-0.5deg);
    transform: rotate(-0.5deg);
  }
  100% {
    top: 52px;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
}
.m-popup-stage.active .m-popup-stage__light-l,
.m-popup-stage.active .m-popup-stage__light-r {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.m-popup-stage.active .m-popup__close {
  opacity: 1;
}

/* book
====================================================================== */
.m-popup-book__base {
  position: relative;
  width: 328px;
  height: 200px;
  perspective: 500px;
}

.m-popup-book__right {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 163px;
  height: 201px;
  background: url(/shared/images/popup/book/book_r.png) no-repeat 100% 100%;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
}

.m-popup-book__left {
  position: absolute;
  right: 163px;
  bottom: 0;
  width: 164px;
  height: 200px;
  perspective: 700px;
}

.m-popup-book__left__front,
.m-popup-book__left__back {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.m-popup-book__left__front {
  left: 100%;
  background: url(/shared/images/popup/book/cover.png) no-repeat 0% 100%;
  -webkit-transform-origin: 0% 100%;
  -moz-transform-origin: 0% 100%;
  -ms-transform-origin: 0% 100%;
  -o-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
}

.m-popup-book__left__back {
  left: 0;
  background: url(/shared/images/popup/book/book_l.png) no-repeat 100% 100%;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
  -webkit-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.m-popup-book__paper {
  position: absolute;
  right: 0;
  bottom: 50px;
  left: 0;
  width: 252px;
  height: 184px;
  margin: auto;
  background: url(/shared/images/popup/book/textarea.png) no-repeat 50% 50%;
}

.m-popup-book__paper .m-popup__txt {
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: center;
  justify-content: center;
  width: 200px;
  height: 130px;
  margin: auto;
  text-align: center;
}

.m-popup-book .m-popup__close span {
  border-color: #2b3c5d;
}

.m-popup-book .m-popup__close span::before,
.m-popup-book .m-popup__close span::after {
  background: #2b3c5d;
}

/* anim
---------------------------------------------------------------------- */
.m-popup-book__base {
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.m-popup-book__left__front,
.m-popup-book__left__back {
  -webkit-transition: 3s cubic-bezier(0.645, 0.045, 0.355, 1) 1.5s;
  -o-transition: 3s cubic-bezier(0.645, 0.045, 0.355, 1) 1.5s;
  transition: 3s cubic-bezier(0.645, 0.045, 0.355, 1) 1.5s;
}

.m-popup-book__paper {
  opacity: 0;
  -webkit-transform: rotateX(-90deg);
  -ms-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.m-popup-book .m-popup__close {
  -webkit-transition: 0.5s ease 5s;
  -o-transition: 0.5s ease 5s;
  transition: 0.5s ease 5s;
}

.m-popup-book.active .m-popup-book__left__front {
  -webkit-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.m-popup-book.active .m-popup-book__left__back {
  -webkit-transform: rotateY(0);
  -ms-transform: rotateY(0);
  transform: rotateY(0);
}

/* active */
.m-popup-book.active .m-popup-book__base {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.m-popup-book.active .m-popup-book__paper {
  -webkit-animation: book-getup 1.5s ease forwards 3.5s;
  animation: book-getup 1.5s ease forwards 3.5s;
}

@keyframes book-getup {
  0% {
    opacity: 1;
  }
  30% {
    -webkit-transform: rotateX(0);
    -ms-transform: rotateX(0);
    transform: rotateX(0);
  }
  40% {
    -webkit-transform: rotateX(-12deg);
    -ms-transform: rotateX(-12deg);
    transform: rotateX(-12deg);
  }
  50% {
    -webkit-transform: rotateX(0);
    -ms-transform: rotateX(0);
    transform: rotateX(0);
  }
  60% {
    -webkit-transform: rotateX(-6deg);
    -ms-transform: rotateX(-6deg);
    transform: rotateX(-6deg);
  }
  70% {
    -webkit-transform: rotateX(0);
    -ms-transform: rotateX(0);
    transform: rotateX(0);
  }
  80% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}
.m-popup-book.active .m-popup__close {
  opacity: 1;
}

/* oc
====================================================================== */
.m-popup-oc__tree {
  position: relative;
  z-index: -1;
}

.m-popup-oc__stage {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 315px;
  height: 46px;
  margin: auto;
  background: url(/shared/images/popup/oc/stage.png) no-repeat 50% 100%;
}

.m-popup-oc__student {
  position: absolute;
  bottom: 33px;
}

.m-popup-oc__student::before {
  position: absolute;
  bottom: 0;
  z-index: 0;
  display: block;
  width: 31px;
  height: 9px;
  content: "";
  background: url(/shared/images/popup/oc/hole.png) no-repeat 50% 50%;
}

.m-popup-oc__student img {
  position: relative;
  z-index: 1;
}

.m-popup-oc__student01 {
  left: 24px;
  padding-left: 6px;
}

.m-popup-oc__student01::before {
  left: 0;
}

.m-popup-oc__student02 {
  right: 8px;
}

.m-popup-oc__student02::before {
  right: 15px;
}

.m-popup-oc__panel {
  position: absolute;
  right: 0;
  bottom: 41px;
  left: 0;
  z-index: 0;
  width: 240px;
  height: 154px;
  margin: auto;
  text-align: center;
  background: url(/shared/images/popup/oc/panel.png) no-repeat 50% 50%;
}

.m-popup-oc__panel .m-popup__txt {
  position: absolute;
  top: 11px;
  right: 0;
  left: 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: center;
  justify-content: center;
  width: 200px;
  height: 120px;
  margin: auto;
}

.m-popup-oc__panel__deco {
  position: absolute;
  top: -6px;
  left: -6px;
  z-index: 1;
}

.m-popup-oc__panel__deco:nth-child(2) {
  right: -6px;
  left: auto;
}

.m-popup-oc__panel__deco:nth-child(2) img {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

.m-popup-oc .m-popup__close {
  top: 0;
  right: 30px;
  z-index: 10;
}

/* anim
---------------------------------------------------------------------- */
.m-popup-oc {
  perspective: 500px;
}

.m-popup-oc__tree {
  opacity: 0;
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.m-popup-oc__stage {
  -webkit-transition: 0.5s linear 0.4s;
  -o-transition: 0.5s linear 0.4s;
  transition: 0.5s linear 0.4s;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.m-popup-oc__student {
  padding-top: 60px;
  overflow: hidden;
}

.m-popup-oc__student::before {
  -webkit-transition: 0.2s linear 0.9s;
  -o-transition: 0.2s linear 0.9s;
  transition: 0.2s linear 0.9s;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}

.m-popup-oc__student img {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.m-popup-oc__student02 {
  right: 8px;
}

.m-popup-oc__panel {
  opacity: 0;
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.m-popup-oc__panel__deco {
  opacity: 0;
  -webkit-transition: 0.7s ease 2.83s;
  -o-transition: 0.7s ease 2.83s;
  transition: 0.7s ease 2.83s;
  -webkit-transform: translateY(-20%);
  -ms-transform: translateY(-20%);
  transform: translateY(-20%);
}

.m-popup-oc .m-popup__close {
  -webkit-transition: 0.5s ease 3.8s;
  -o-transition: 0.5s ease 3.8s;
  transition: 0.5s ease 3.8s;
}

/* active */
.m-popup-oc.active .m-popup-oc__tree {
  -webkit-animation: tree-getup 1.3s ease forwards;
  animation: tree-getup 1.3s ease forwards;
}

@keyframes tree-getup {
  0% {
    opacity: 1;
  }
  26% {
    -webkit-transform: rotateX(-13deg);
    -ms-transform: rotateX(-13deg);
    transform: rotateX(-13deg);
  }
  36% {
    -webkit-transform: rotateX(5deg);
    -ms-transform: rotateX(5deg);
    transform: rotateX(5deg);
  }
  46% {
    -webkit-transform: rotateX(-4deg);
    -ms-transform: rotateX(-4deg);
    transform: rotateX(-4deg);
  }
  56% {
    -webkit-transform: rotateX(3deg);
    -ms-transform: rotateX(3deg);
    transform: rotateX(3deg);
  }
  68% {
    -webkit-transform: rotateX(-2deg);
    -ms-transform: rotateX(-2deg);
    transform: rotateX(-2deg);
  }
  75% {
    -webkit-transform: rotateX(1deg);
    -ms-transform: rotateX(1deg);
    transform: rotateX(1deg);
  }
  83% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}
.m-popup-oc.active .m-popup-oc__stage {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.m-popup-oc.active .m-popup-oc__student::before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.m-popup-oc.active .m-popup-oc__student img {
  -webkit-animation: jump 1s linear 1.4s forwards;
  animation: jump 1s linear 1.4s forwards;
}

@keyframes jump {
  50% {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  60% {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  82% {
    -webkit-transform: translateY(2%);
    -ms-transform: translateY(2%);
    transform: translateY(2%);
  }
  91% {
    -webkit-transform: translateY(-6%);
    -ms-transform: translateY(-6%);
    transform: translateY(-6%);
  }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.m-popup-oc.active .m-popup-oc__panel {
  -webkit-animation: oc-getup 1s ease 1.73s forwards;
  animation: oc-getup 1s ease 1.73s forwards;
}

@keyframes oc-getup {
  0% {
    opacity: 1;
  }
  60% {
    -webkit-transform: rotateX(-13deg);
    -ms-transform: rotateX(-13deg);
    transform: rotateX(-13deg);
  }
  70% {
    -webkit-transform: rotateX(5deg);
    -ms-transform: rotateX(5deg);
    transform: rotateX(5deg);
  }
  80% {
    -webkit-transform: rotateX(-4deg);
    -ms-transform: rotateX(-4deg);
    transform: rotateX(-4deg);
  }
  90% {
    -webkit-transform: rotateX(3deg);
    -ms-transform: rotateX(3deg);
    transform: rotateX(3deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}
.m-popup-oc.active .m-popup-oc__panel__deco {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.m-popup-oc.active .m-popup__close {
  opacity: 1;
}

.secAbout {
  padding: 55px 0 125px;
  overflow: hidden;
  background: #fcf1f1;
}
@media (min-width: 768px) {
  .secAbout {
    position: relative;
    padding: 100px 0 230px;
  }
}
.secAbout__object {
  position: absolute;
}
.secAbout__object--1 {
  top: -32px;
  left: 88px;
}
.secAbout__object--2 {
  top: 33px;
  right: -18px;
}
.secAbout__object--3 {
  bottom: 490px;
  left: -100px;
}
.secAbout__object--4 {
  right: -107px;
  bottom: 12px;
}
.secAbout__hdg {
  text-align: center;
}
.secAbout__body {
  margin: 35px 0 0;
}
@media (min-width: 768px) {
  .secAbout__body {
    margin: 55px 0 0;
  }
}
.secAbout__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
@media (min-width: 768px) {
  .secAbout__inner {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0;
    justify-content: space-between;
    width: 1146px;
    margin: -55px auto 0;
  }
}
.secAbout__inner::before, .secAbout__inner::after {
  position: absolute;
  z-index: 6;
  pointer-events: none;
  content: "";
  background: no-repeat center/contain;
}
.secAbout__inner::before {
  top: -60px;
  right: -20px;
  width: 186px;
  height: 220px;
  background-image: url("/assets/images/pages/index/about_deco1.svg");
}
@media (min-width: 768px) {
  .secAbout__inner::before {
    top: -170px;
    right: -165px;
    width: 615px;
    height: 656px;
    background-image: url("/assets/images/pages/index/about_deco1_md.svg");
  }
}
.secAbout__inner::after {
  right: -10px;
  bottom: -100px;
  width: 252px;
  height: 196px;
  background-image: url("/assets/images/pages/index/about_deco2.svg");
}
@media (min-width: 768px) {
  .secAbout__inner::after {
    right: -85px;
    bottom: -15px;
    width: 725px;
    height: 675px;
    background-image: url("/assets/images/pages/index/about_deco2_md.svg");
  }
}
.secAbout__inner .aboutItem {
  position: relative;
  z-index: 0;
  width: 289px;
  height: 244px;
}
@media (min-width: 768px) {
  .secAbout__inner .aboutItem {
    z-index: 0;
    width: 547px;
    height: 464px;
    margin: 55px 0 0;
  }
}
@media (hover: hover) and (pointer: fine) {
  .secAbout__inner .aboutItem:hover .aboutItem__typography {
    filter: brightness(1000%);
  }
  .secAbout__inner .aboutItem:hover .aboutItem__bg .fill-white {
    fill: #e52d8a;
  }
  .secAbout__inner .aboutItem:hover .aboutItem__deco path {
    fill: #e52d8a !important;
  }
}
.secAbout__inner .aboutItem::before, .secAbout__inner .aboutItem::after {
  position: absolute;
  z-index: -1;
  pointer-events: none;
  content: "";
  background: no-repeat center/contain;
}
.secAbout__inner .aboutItem__typography {
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 1;
  transition: filter 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
@media (min-width: 768px) {
  .secAbout__inner .aboutItem__typography {
    left: 30px;
  }
}
.secAbout__inner .aboutItem__bg .fill-white {
  fill: #fff;
  transition: fill 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secAbout__inner .aboutItem__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 9px 11px 0;
}
@media (min-width: 768px) {
  .secAbout__inner .aboutItem__img {
    padding: 18px 21px 0;
  }
}
.secAbout__inner .aboutItem__img img {
  width: 100%;
  height: auto;
}
.secAbout__inner .aboutItem__deco {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.secAbout__inner .aboutItem__deco path {
  transition: fill 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secAbout__inner .aboutItem__ttl {
  position: absolute;
  bottom: 10px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  padding: 0 0 5px;
  font-family: "Zen Old Mincho", serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: #fff;
  text-align: center;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .secAbout__inner .aboutItem__ttl {
    bottom: 20px;
    height: 105px;
    padding: 0 0 25px;
    font-size: 30px;
  }
}
.secAbout__inner .aboutItem:nth-child(even) {
  margin: 0 0 0 auto;
}
@media (min-width: 768px) {
  .secAbout__inner .aboutItem:nth-child(even) {
    margin: 55px 0 0;
    transform: translateY(105px);
  }
}
.secAbout__inner .aboutItem--feature {
  z-index: 4;
}
.secAbout__inner .aboutItem--feature::before {
  top: 94px;
  right: -60px;
  width: 102px;
  height: 131px;
  background-image: url("/assets/images/pages/index/about_item-bg-deco1-1.svg");
}
@media (min-width: 768px) {
  .secAbout__inner .aboutItem--feature::before {
    top: 32px;
    right: -87px;
    width: 195px;
    height: 249px;
  }
}
.secAbout__inner .aboutItem--step {
  z-index: 3;
}
.secAbout__inner .aboutItem--step::before {
  top: 104px;
  right: -80px;
  width: 194px;
  height: 168px;
  background-image: url("/assets/images/pages/index/about_item-bg-deco2-1.svg");
}
@media (min-width: 768px) {
  .secAbout__inner .aboutItem--step::before {
    top: 248px;
    right: -66px;
    width: 289px;
    height: 250px;
  }
}
.secAbout__inner .aboutItem--international {
  z-index: 2;
}
.secAbout__inner .aboutItem--international::before {
  top: -148px;
  left: -72px;
  width: 239px;
  height: 225px;
  background-image: url("/assets/images/pages/index/about_item-bg-deco3-1.svg");
}
@media (min-width: 768px) {
  .secAbout__inner .aboutItem--international::before {
    top: -182px;
    left: -160px;
    width: 350px;
    height: 329px;
  }
}
.secAbout__inner .aboutItem--international::after {
  top: 10px;
  right: -55px;
  width: 163px;
  height: 137px;
  background-image: url("/assets/images/pages/index/about_item-bg-deco3-2.svg");
}
@media (min-width: 768px) {
  .secAbout__inner .aboutItem--international::after {
    top: 16px;
    right: -106px;
    width: 310px;
    height: 260px;
  }
}
.secAbout__inner .aboutItem--openCampus {
  z-index: 1;
}
@media (min-width: 768px) {
  .secAbout__inner .aboutItem--openCampus::before {
    top: -24px;
    right: -169px;
    width: 317px;
    height: 275px;
    background-image: url("/assets/images/pages/index/about_item-bg-deco4-1.svg");
  }
}
.secAbout__inner .aboutItem--schoolTripTimeline {
  z-index: 0;
}
.secAbout__inner .aboutItem--schoolTripTimeline::before {
  top: 125px;
  left: -58px;
  width: 160px;
  height: 138px;
  background-image: url("/assets/images/pages/index/about_item-bg-deco5-1.svg");
}
@media (min-width: 768px) {
  .secAbout__inner .aboutItem--schoolTripTimeline::before {
    top: 237px;
    left: -109px;
    width: 303px;
    height: 263px;
  }
}
.secAbout__inner .aboutItem--schoolTripTimeline::after {
  top: -83px;
  right: -107px;
  width: 223px;
  height: 217px;
  background-image: url("/assets/images/pages/index/about_item-bg-deco5-2.svg");
}
@media (min-width: 768px) {
  .secAbout__inner .aboutItem--schoolTripTimeline::after {
    top: -131px;
    right: -48px;
    width: 327px;
    height: 318px;
  }
}

.secAbout .secAbout__hdg {
  opacity: 0;
}
.secAbout .secAbout__hdg.is-active {
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.secAbout .secAbout__inner .aboutItem {
  opacity: 0;
}
.secAbout .secAbout__inner .aboutItem.is-active {
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.secDiary {
  background: inherit;
}

.secSchool__head {
  background: var(--headBg);
}
@media (min-width: 768px) {
  .secSchool__head {
    display: flex;
  }
}
.secSchool__head .headVisual {
  width: 50%;
}
@media (min-width: 768px) {
  .secSchool__head .headVisual {
    position: relative;
  }
}
@media (min-width: 768px) {
  .secSchool__head .headVisual::before {
    position: absolute;
    content: "";
    background: no-repeat center/contain;
    mix-blend-mode: overlay;
  }
}
.secSchool__head .headVisual img {
  width: 100%;
  height: auto;
}
.secSchool__head .headContents {
  position: relative;
  z-index: 0;
  padding: 65px 35px 95px;
  overflow: hidden;
  font-family: "Zen Old Mincho", serif;
  color: #fff;
}
@media (min-width: 768px) {
  .secSchool__head .headContents {
    width: 50%;
    padding: 7.6388888889% 6.9444444444% 0;
  }
}
.secSchool__head .headContents::before {
  position: absolute;
  right: -15px;
  bottom: 20px;
  z-index: -1;
  width: 278px;
  height: 219px;
  content: "";
}
@media (min-width: 768px) {
  .secSchool__head .headContents::before {
    right: 1px;
    bottom: 10px;
    width: 520px;
    height: 410px;
  }
}
.secSchool__head .headContents__frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.secSchool__head .headContents__frame::before, .secSchool__head .headContents__frame::after {
  position: absolute;
  width: 44px;
  height: 44px;
  content: "";
  background: #fff no-repeat center/contain;
  mask-image: url("/assets/images/pages/common/compornent_corner-frame.svg");
}
.secSchool__head .headContents__frame--top::before, .secSchool__head .headContents__frame--top::after {
  top: 20px;
}
.secSchool__head .headContents__frame--top::before {
  left: 20px;
}
.secSchool__head .headContents__frame--top::after {
  right: 20px;
  transform: rotate(90deg);
}
.secSchool__head .headContents__frame--bottom::before, .secSchool__head .headContents__frame--bottom::after {
  bottom: 20px;
}
.secSchool__head .headContents__frame--bottom::before {
  left: 20px;
  transform: rotate(270deg);
}
.secSchool__head .headContents__frame--bottom::after {
  right: 20px;
  transform: rotate(180deg);
}
.secSchool__head .headContents .contentsHdg {
  position: relative;
  font-size: 46px;
  font-weight: 400;
}
@media (min-width: 768px) {
  .secSchool__head .headContents .contentsHdg {
    font-size: 74px;
  }
}
.secSchool__head .headContents .contentsImg {
  margin: 25px 0 0;
}
.secSchool__head .headContents .contentsImg img {
  width: 100%;
  height: auto;
}
.secSchool__head .headContents .contentsLead {
  position: relative;
  margin: 25px 0 0;
  font-size: 28px;
  line-height: 1.6;
  letter-spacing: 0.25em;
}
@media (min-width: 768px) {
  .secSchool__head .headContents .contentsLead {
    margin: 4.1666666667% 0 0;
    font-size: 36px;
  }
}
.secSchool__head .headContents .contentsLead::before {
  position: absolute;
  width: 53px;
  height: 31px;
  content: "";
  background: url("/assets/images/pages/index/school_visual-deco2.svg") no-repeat center/contain;
}
@media (min-width: 768px) {
  .secSchool__head .headContents .contentsLead::before {
    width: 44px;
    height: 61px;
    background: url("/assets/images/pages/index/school_visual-deco1.svg") no-repeat center/contain;
  }
}
.secSchool__head .headContents .contentsTxt {
  margin: 20px 0 0;
  font-size: 14px;
  line-height: 2.235;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .secSchool__head .headContents .contentsTxt {
    margin: 1.3888888889% 0 0;
    font-size: 17px;
  }
}
.secSchool__head .headContents .contentsTxt__inner {
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  .secSchool__head .headContents .contentsTxt__inner::before {
    position: absolute;
    width: 72px;
    height: 43px;
    content: "";
    background: url("/assets/images/pages/index/school_visual-deco2.svg") no-repeat center/contain;
  }
}
.secSchool__head .headContents .contentsLinkList {
  display: flex;
  flex-wrap: wrap;
  margin: 15px -10px 0;
  color: #fff;
}
@media (min-width: 768px) {
  .secSchool__head .headContents .contentsLinkList {
    margin: 3.125% -17px 0;
  }
}
.secSchool__head .headContents .contentsLinkList__item {
  width: 50%;
  padding: 0 10px;
}
@media (min-width: 768px) {
  .secSchool__head .headContents .contentsLinkList__item {
    padding: 0 17px;
  }
}
.secSchool__head .headContents .contentsLinkList__item .link {
  display: flex;
  align-items: center;
  height: 58px;
  font-family: "Zen Old Mincho", serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .secSchool__head .headContents .contentsLinkList__item .link {
    height: 65px;
    font-size: 20px;
    border-bottom: 1px solid rgba(217, 217, 217, 0.3);
  }
}
.secSchool__head .headContents .contentsLinkList__item .link__txt {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
@media (hover: hover) and (pointer: fine) {
  .secSchool__head .headContents .contentsLinkList__item .link__txt:hover {
    opacity: 0.6;
  }
}
.secSchool__head .headContents .contentsLinkList__item .link__txt::after {
  display: inline-block;
  width: 6.5px;
  height: 11px;
  margin: 0 0 0 12px;
  content: "";
  background: #fff no-repeat center/contain;
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 6.5 11"><path d="M1 11c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.8-3.8L.3 1.7C-.1 1.3-.1.7.3.3s1-.4 1.4 0l4.5 4.5c.4.4.4 1 0 1.4l-4.5 4.5c-.2.2-.4.3-.7.3z"/></svg>');
}
.secSchool__head .headContents .contentsLinkList__item--full {
  width: 100%;
}
.secSchool__head .headContents .contentsLinkList__item:nth-child(n+3) .link {
  border-top: 1px solid rgba(217, 217, 217, 0.3);
}
@media (min-width: 768px) {
  .secSchool__head .headContents .contentsLinkList__item:nth-child(n+3) .link {
    border-top: none;
  }
}
@media (min-width: 768px) {
  .secSchool__head .headContents .contentsLinkList__item:last-child .link {
    border-bottom: none;
  }
}
.secSchool__course {
  overflow: hidden;
}
.secSchool__course .course {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background: var(--bgColor);
}
@media (min-width: 768px) {
  .secSchool__course .course {
    display: flex;
    width: 50%;
  }
}
.secSchool__course .course::before, .secSchool__course .course::after {
  position: absolute;
  z-index: -1;
  content: "";
}
.secSchool__course .course::before {
  top: -22px;
  left: 14px;
  width: 89px;
  height: 104px;
  background: url("/assets/images/pages/index/school_course_bg-deco1.svg") no-repeat center/contain;
}
.secSchool__course .course::after {
  top: 10px;
  right: 5px;
  width: 88px;
  height: 112px;
  background: url("/assets/images/pages/index/school_course_bg-deco2.svg") no-repeat center/contain;
}
.secSchool__course .course__container {
  position: relative;
  padding: 35px 25px 40px 50px;
}
.secSchool__course .course__container::before {
  position: absolute;
  bottom: 60px;
  left: 30px;
  z-index: -1;
  width: 115px;
  height: 145px;
  content: "";
  background: url("/assets/images/pages/index/school_course_bg-deco3.svg") no-repeat center/contain;
}
.secSchool__course .course__visual {
  position: relative;
  display: inline-block;
}
@media (hover: hover) and (pointer: fine) {
  .secSchool__course .course__visual:hover .visualImg::before {
    opacity: 0.4;
  }
  .secSchool__course .course__visual:hover .visualHdg {
    color: #fff;
  }
  .secSchool__course .course__visual:hover .visualHdg h3::before,
  .secSchool__course .course__visual:hover .visualHdg p::before {
    background: var(--hdgColor);
  }
  .secSchool__course .course__visual:hover .visualIcon::after {
    opacity: 1;
    transform: scale(1.15);
  }
}
.secSchool__course .course__visual .visualImg {
  position: relative;
}
.secSchool__course .course__visual .visualImg::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  /* stylelint-disable */
  background: linear-gradient(0, var(--iconColor), var(--iconColor) 20%, transparent 75%);
  /* stylelint-enable */
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secSchool__course .course__visual .visualImg img {
  width: 100%;
  height: auto;
}
.secSchool__course .course__visual .visualHdg {
  position: absolute;
  top: 10px;
  left: -25px;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  font-family: "Zen Old Mincho", serif;
  color: var(--hdgColor);
  transition: color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
@media (min-width: 768px) {
  .secSchool__course .course__visual .visualHdg {
    top: 15px;
    left: -30px;
  }
}
.secSchool__course .course__visual .visualHdg__main, .secSchool__course .course__visual .visualHdg__sub {
  margin: 0 0.2rem 0 0;
  letter-spacing: 0.075em;
  writing-mode: vertical-rl;
}
.secSchool__course .course__visual .visualHdg__main > *, .secSchool__course .course__visual .visualHdg__sub > * {
  font-weight: 400;
  line-height: 1;
}
.secSchool__course .course__visual .visualHdg__main > *::before, .secSchool__course .course__visual .visualHdg__sub > *::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background: #fff;
}
.secSchool__course .course__visual .visualHdg__main .num, .secSchool__course .course__visual .visualHdg__sub .num {
  text-combine-upright: all;
}
.secSchool__course .course__visual .visualHdg__main > h3 {
  position: relative;
  z-index: 0;
  padding: 5px;
  font-size: 28px;
}
@media (min-width: 768px) {
  .secSchool__course .course__visual .visualHdg__main > h3 {
    padding: 8px 5px;
    font-size: 40px;
  }
}
.secSchool__course .course__visual .visualHdg__sub > p {
  position: relative;
  z-index: 0;
  padding: 5px;
  font-size: 14px;
}
@media (min-width: 768px) {
  .secSchool__course .course__visual .visualHdg__sub > p {
    padding: 8px 5px;
    font-size: 20px;
  }
}
.secSchool__course .course__visual .visualIcon {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background: var(--iconColor);
  border-radius: 50%;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
}
@media (min-width: 768px) {
  .secSchool__course .course__visual .visualIcon {
    right: 10px;
    bottom: 10px;
  }
}
.secSchool__course .course__visual .visualIcon::before {
  width: 8px;
  height: 14px;
  content: "";
  background: #fff no-repeat center/contain;
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 6.5 11"><path d="M1 11c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.8-3.8L.3 1.7C-.1 1.3-.1.7.3.3s1-.4 1.4 0l4.5 4.5c.4.4.4 1 0 1.4l-4.5 4.5c-.2.2-.4.3-.7.3z"/></svg>');
}
.secSchool__course .course__visual .visualIcon::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  border: 2px solid var(--iconColor);
  border-radius: 50%;
  transition: transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secSchool__course .course__txt {
  margin: 20px 0 0;
  font-family: "Zen Old Mincho", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 2.125;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .secSchool__course .course__txt {
    margin: 25px 0 0;
    font-size: 16px;
  }
}
.secSchool__course .course--standard {
  --hdgColor: #fac529;
  --iconColor: #f9bf11;
  --bgColor: #f8edcd;
}
.secSchool__course .course--art {
  --hdgColor: #a0ce7e;
  --iconColor: #95c970;
  --bgColor: #ddedd1;
}
.secSchool__course .course--advanced {
  --hdgColor: #6879ba;
  --iconColor: #6879ba;
  --bgColor: #eaedfa;
}
.secSchool__course .course--nurse {
  --hdgColor: #5bc3c4;
  --iconColor: #49bcbd;
  --bgColor: #d4edee;
}
.secSchool__course .course--global {
  --hdgColor: #e984a1;
  --iconColor: #e67696;
  --bgColor: #faecf0;
}
.secSchool__course .course--performing {
  --hdgColor: #c186b9;
  --iconColor: #ba79b1;
  --bgColor: #f3e4f1;
}
.secSchool__course .course--career {
  --hdgColor: #6abd79;
  --iconColor: #6abd79;
  --bgColor: #dff0e2;
}
.secSchool__bnr {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 35px 15px;
  background: var(--bnrBg);
}
@media (min-width: 768px) {
  .secSchool__bnr {
    flex-direction: row;
    gap: 40px;
    justify-content: center;
    padding: 50px 0;
  }
}
.secSchool__bnr::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background: url("/assets/images/pages/index/repeat-bg_school_bnr.png") repeat center/59px auto;
  opacity: 0.2;
}
.secSchool__bnr .bnr {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
@media (min-width: 768px) {
  .secSchool__bnr .bnr {
    width: 588px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .secSchool__bnr .bnr:hover {
    opacity: 0.6;
  }
}
.secSchool__bnr .bnr__container {
  position: relative;
  display: block;
  padding: 8px;
  border-image-slice: 30;
  border-image-width: 25px;
  border-image-outset: 0;
  border-image-repeat: round;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
@media (hover: hover) and (pointer: fine) {
  .secSchool__bnr .bnr__container:hover {
    opacity: 0.6;
  }
}
.secSchool__bnr .bnr__inner {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.secSchool__bnr .bnr__inner::before {
  position: absolute;
  top: 7px;
  right: 7px;
  bottom: 7px;
  left: 7px;
  z-index: -1;
  content: "";
}
.secSchool__bnr .bnr__img {
  width: 112px;
}
@media (min-width: 768px) {
  .secSchool__bnr .bnr__img {
    width: auto;
  }
}
.secSchool__bnr .bnr__img img {
  width: 100%;
  height: auto;
}
.secSchool__bnr .bnr__txt {
  margin: 0 0 0 15px;
  font-family: "Zen Old Mincho", serif;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 0;
  color: #e52d8a;
}
@media (min-width: 768px) {
  .secSchool__bnr .bnr__txt {
    margin: 0 0 0 30px;
    font-size: 22px;
  }
}
.secSchool__bnr .bnr__arw {
  position: absolute;
  top: 50%;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
}
.secSchool__bnr .bnr__arw::before {
  display: block;
  width: 6.5px;
  height: 11px;
  content: "";
  background: #e52d8a no-repeat center/contain;
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 6.5 11"><path d="M1 11c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.8-3.8L.3 1.7C-.1 1.3-.1.7.3.3s1-.4 1.4 0l4.5 4.5c.4.4.4 1 0 1.4l-4.5 4.5c-.2.2-.4.3-.7.3z"/></svg>');
}
.secSchool--j .secSchool__head {
  --headBg: #f18d02;
}
@media (min-width: 768px) {
  .secSchool--j .secSchool__head .headVisual::before {
    top: 23px;
    left: 10px;
    width: 192px;
    height: 762px;
    background-image: url("/assets/images/pages/index/school_visual-typography-j_md.svg");
  }
}
.secSchool--j .secSchool__head .headContents::before {
  background: url("/assets/images/pages/index/school_visual-j-object.svg") no-repeat center/contain;
}
.secSchool--j .secSchool__head .headContents .contentsHdg::before {
  position: absolute;
  top: 25px;
  right: -10px;
  width: 30px;
  height: 42px;
  content: "";
  background: url("/assets/images/pages/index/school_visual-deco1.svg") no-repeat center/contain;
}
@media (min-width: 768px) {
  .secSchool--j .secSchool__head .headContents .contentsHdg::before {
    display: none;
  }
}
.secSchool--j .secSchool__head .headContents .contentsLead::before {
  top: 0;
  right: -10px;
}
@media (min-width: 768px) {
  .secSchool--j .secSchool__head .headContents .contentsLead::before {
    top: -59px;
    right: auto;
    left: -61px;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .secSchool--j .secSchool__head .headContents .contentsTxt__inner::before {
    right: 2px;
    bottom: -63px;
  }
}
@media (min-width: 768px) {
  .secSchool--j .secSchool__course .courseWrap {
    display: flex;
  }
}
@media (min-width: 768px) {
  .secSchool--j .secSchool__course .course:nth-child(odd)::before {
    top: 10px;
    left: calc(50% - 410px);
    width: 180px;
    height: 210px;
    background: url("/assets/images/pages/index/school_course_bg-deco1-1_md.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  .secSchool--j .secSchool__course .course:nth-child(odd)::after {
    top: 97px;
    left: calc(50% + 226px);
    width: 164px;
    height: 209px;
    background: url("/assets/images/pages/index/school_course_bg-deco1-2_md.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  .secSchool--j .secSchool__course .course:nth-child(odd) .course__container {
    padding: 60px 100px 100px 125px;
  }
}
@media (min-width: 768px) {
  .secSchool--j .secSchool__course .course:nth-child(odd) .course__container::before {
    bottom: 75px;
    left: calc(50% - 375px);
    width: 180px;
    height: 228px;
    background: url("/assets/images/pages/index/school_course_bg-deco1-3_md.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  .secSchool--j .secSchool__course .course:nth-child(even)::before {
    top: -18px;
    left: calc(50% + 176px);
    width: 161px;
    height: 207px;
    background: url("/assets/images/pages/index/school_course_bg-deco2-1_md.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  .secSchool--j .secSchool__course .course:nth-child(even)::after {
    top: auto;
    bottom: 216px;
    left: calc(50% - 425px);
    width: 273px;
    height: 247px;
    background: url("/assets/images/pages/index/school_course_bg-deco2-2_md.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  .secSchool--j .secSchool__course .course:nth-child(even) .course__container {
    padding: 60px 105px 100px 115px;
  }
}
@media (min-width: 768px) {
  .secSchool--j .secSchool__course .course:nth-child(even) .course__container::before {
    bottom: -53px;
    left: calc(50% + 158px);
    width: 282px;
    height: 275px;
    background: url("/assets/images/pages/index/school_course_bg-deco2-3_md.svg") no-repeat center/contain;
  }
}
.secSchool--j .secSchool__bnr {
  --bnrBg: linear-gradient(90deg, #ffb11b, #f18d02);
}
.secSchool--j .secSchool__bnr .bnr__container {
  border-image-source: url("/assets/images/pages/common/compornent_border-frame-yellow.png");
}
.secSchool--j .secSchool__bnr .bnr__inner::before {
  background: #ffef9d;
}
.secSchool--h .secSchool__head {
  --headBg: #b72f8c;
}
@media (min-width: 768px) {
  .secSchool--h .secSchool__head .headVisual::before {
    top: 44px;
    right: 28px;
    width: 103px;
    height: 733px;
    background-image: url("/assets/images/pages/index/school_visual-typography-h_md.svg");
  }
}
.secSchool--h .secSchool__head .headContents::before {
  background: url("/assets/images/pages/index/school_visual-h-object.svg") no-repeat center/contain;
}
.secSchool--h .secSchool__head .headContents .contentsLead::before {
  right: 10px;
  bottom: 10px;
}
@media (min-width: 768px) {
  .secSchool--h .secSchool__head .headContents .contentsLead::before {
    top: 5px;
    right: 10px;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .secSchool--h .secSchool__head .headContents .contentsTxt__inner::before {
    right: 65px;
    bottom: 0;
  }
}
@media (min-width: 768px) {
  .secSchool--h .secSchool__course .courseWrap {
    display: inline-flex;
  }
}
@media (min-width: 768px) {
  .secSchool--h .secSchool__course .courseWrap .course__container {
    padding: 70px 75px 90px 125px;
  }
}
@media (min-width: 768px) {
  .secSchool--h .secSchool__course .courseWrap .course:nth-child(odd)::before {
    top: 20px;
    left: calc(50% - 370px);
    width: 180px;
    height: 210px;
    background: url("/assets/images/pages/index/school_course_bg-deco3-1_md.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  .secSchool--h .secSchool__course .courseWrap .course:nth-child(odd)::after {
    top: 35px;
    left: calc(50% + 175px);
    width: 164px;
    height: 209px;
    background: url("/assets/images/pages/index/school_course_bg-deco3-2_md.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  .secSchool--h .secSchool__course .courseWrap .course:nth-child(odd) .course__container::before {
    bottom: 32px;
    left: calc(50% - 335px);
    width: 180px;
    height: 228px;
    background: url("/assets/images/pages/index/school_course_bg-deco3-3_md.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  .secSchool--h .secSchool__course .courseWrap .course:nth-child(even)::before {
    top: -50px;
    left: calc(50% + 85px);
    width: 258px;
    height: 240px;
    background: url("/assets/images/pages/index/school_course_bg-deco4-1_md.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  .secSchool--h .secSchool__course .courseWrap .course:nth-child(even)::after {
    top: auto;
    bottom: 150px;
    left: calc(50% - 390px);
    width: 219px;
    height: 256px;
    background: url("/assets/images/pages/index/school_course_bg-deco4-2_md.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  .secSchool--h .secSchool__course .courseWrap .course:nth-child(even) .course__container::before {
    bottom: 155px;
    left: calc(50% + 155px);
    width: 261px;
    height: 244px;
    background: url("/assets/images/pages/index/school_course_bg-deco4-3_md.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  .secSchool--h .secSchool__course .course {
    width: 640px;
  }
}
.secSchool--h .secSchool__bnr {
  --bnrBg: linear-gradient(90deg, #e03fad, #b72f8c);
}
.secSchool--h .secSchool__bnr .bnr__container {
  border-image-source: url("/assets/images/pages/common/compornent_border-frame-pink.png");
}
.secSchool--h .secSchool__bnr .bnr__inner::before {
  background: #fad9ea;
}
@media (min-width: 768px) {
  .secSchool--headReverse .secSchool__head {
    flex-direction: row-reverse;
  }
}

.secSchool .secSchool__head .headContents .contentsHdg .chara {
  display: inline-block;
  opacity: 0;
  transform: rotateY(-90deg);
  backface-visibility: hidden;
}
.secSchool .secSchool__head .headContents .contentsHdg.is-active .chara {
  opacity: 1;
  transform: rotateY(0);
}
.secSchool .secSchool__head .headContents .contentsHdg.is-active .chara--1 {
  transition: transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.1s;
}
.secSchool .secSchool__head .headContents .contentsHdg.is-active .chara--2 {
  transition: transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.2s;
}
.secSchool .secSchool__head .headContents .contentsHdg.is-active .chara--3 {
  transition: transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.3s;
}
.secSchool .secSchool__head .headContents .contentsHdg.is-active .chara--4 {
  transition: transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.4s;
}
.secSchool .secSchool__head .headContents .contentsHdg.is-active .chara--5 {
  transition: transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.5s;
}
.secSchool .secSchool__head .headContents .contentsHdg.is-active .chara--6 {
  transition: transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.6s;
}

.secSchool .secSchool__head .headContents .contentsImg,
.secSchool .secSchool__head .headContents .contentsLead,
.secSchool .secSchool__head .headContents .contentsTxt,
.secSchool .secSchool__head .headContents .contentsLinkList {
  opacity: 0;
}
.secSchool .secSchool__head .headContents .contentsImg.is-active,
.secSchool .secSchool__head .headContents .contentsLead.is-active,
.secSchool .secSchool__head .headContents .contentsTxt.is-active,
.secSchool .secSchool__head .headContents .contentsLinkList.is-active {
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.secSchool .secSchool__course .course .visualImg,
.secSchool .secSchool__course .course .visualIcon {
  opacity: 0;
}
.secSchool .secSchool__course .course .visualHdg__main > h3::before {
  height: 0;
}
.secSchool .secSchool__course .course .visualHdg__main > h3 .chara {
  opacity: 0;
}
.secSchool .secSchool__course .course .visualHdg__sub > p::before {
  height: 0;
}
.secSchool .secSchool__course .course .visualHdg__sub > p .chara {
  opacity: 0;
}
.secSchool .secSchool__course .course .course__txt {
  opacity: 0;
}
.secSchool .secSchool__course .course.is-active .visualImg,
.secSchool .secSchool__course .course.is-active .visualIcon {
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3::before {
  height: 100%;
  transition: height 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara {
  opacity: 1;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--1 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.38s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--2 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.46s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--3 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.54s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--4 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.62s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--5 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.7s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--6 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.78s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--7 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.86s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--8 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.94s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--9 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.02s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--10 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.1s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--11 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.18s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--12 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.26s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--13 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.34s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--14 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.42s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--15 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.5s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--16 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.58s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--17 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.66s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--18 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.74s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--19 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.82s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__main > h3 .chara--20 {
  transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.9s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p::before {
  height: 100%;
  transition: height 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s, background-color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara {
  opacity: 1;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--1 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.68s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--2 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.76s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--3 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.84s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--4 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 0.92s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--5 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--6 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.08s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--7 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.16s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--8 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.24s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--9 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.32s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--10 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.4s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--11 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.48s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--12 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.56s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--13 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.64s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--14 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.72s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--15 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.8s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--16 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.88s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--17 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 1.96s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--18 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 2.04s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--19 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 2.12s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--20 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 2.2s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--21 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 2.28s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--22 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 2.36s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--23 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 2.44s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--24 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 2.52s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--25 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 2.6s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--26 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 2.68s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--27 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 2.76s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--28 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 2.84s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--29 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 2.92s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--30 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 3s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--31 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 3.08s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--32 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 3.16s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--33 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 3.24s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--34 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 3.32s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--35 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 3.4s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--36 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 3.48s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--37 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 3.56s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--38 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 3.64s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--39 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 3.72s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--40 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 3.8s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--41 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 3.88s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--42 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 3.96s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--43 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 4.04s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--44 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 4.12s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--45 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 4.2s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--46 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 4.28s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--47 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 4.36s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--48 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 4.44s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--49 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 4.52s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub > p .chara--50 {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-delay: 4.6s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--1 {
  transition-delay: 0.62s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--2 {
  transition-delay: 0.64s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--3 {
  transition-delay: 0.66s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--4 {
  transition-delay: 0.68s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--5 {
  transition-delay: 0.7s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--6 {
  transition-delay: 0.72s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--7 {
  transition-delay: 0.74s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--8 {
  transition-delay: 0.76s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--9 {
  transition-delay: 0.78s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--10 {
  transition-delay: 0.8s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--11 {
  transition-delay: 0.82s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--12 {
  transition-delay: 0.84s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--13 {
  transition-delay: 0.86s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--14 {
  transition-delay: 0.88s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--15 {
  transition-delay: 0.9s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--16 {
  transition-delay: 0.92s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--17 {
  transition-delay: 0.94s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--18 {
  transition-delay: 0.96s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--19 {
  transition-delay: 0.98s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--20 {
  transition-delay: 1s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--21 {
  transition-delay: 1.02s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--22 {
  transition-delay: 1.04s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--23 {
  transition-delay: 1.06s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--24 {
  transition-delay: 1.08s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--25 {
  transition-delay: 1.1s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--26 {
  transition-delay: 1.12s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--27 {
  transition-delay: 1.14s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--28 {
  transition-delay: 1.16s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--29 {
  transition-delay: 1.18s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--30 {
  transition-delay: 1.2s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--31 {
  transition-delay: 1.22s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--32 {
  transition-delay: 1.24s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--33 {
  transition-delay: 1.26s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--34 {
  transition-delay: 1.28s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--35 {
  transition-delay: 1.3s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--36 {
  transition-delay: 1.32s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--37 {
  transition-delay: 1.34s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--38 {
  transition-delay: 1.36s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--39 {
  transition-delay: 1.38s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--40 {
  transition-delay: 1.4s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--41 {
  transition-delay: 1.42s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--42 {
  transition-delay: 1.44s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--43 {
  transition-delay: 1.46s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--44 {
  transition-delay: 1.48s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--45 {
  transition-delay: 1.5s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--46 {
  transition-delay: 1.52s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--47 {
  transition-delay: 1.54s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--48 {
  transition-delay: 1.56s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--49 {
  transition-delay: 1.58s;
}
.secSchool .secSchool__course .course.is-active .visualHdg__sub--long > p .chara--50 {
  transition-delay: 1.6s;
}
.secSchool .secSchool__course .course.is-active .course__txt {
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) 0.7s;
}

.secSchool .secSchool__bnr .bnr {
  opacity: 0;
}
.secSchool .secSchool__bnr .bnr.is-active {
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
@media (min-width: 768px) {
  .secSchool .secSchool__bnr .bnr.is-active:nth-child(2) {
    transition-delay: 0.2s;
  }
}